import React from 'react'
import { Spin, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import GAds from 'lib/component/gads'
import { processRequest, trackEvent } from 'lib'
import GPT from './gpt'
import Article from './article'
import Quan from './quan'
import Ad from '../home/ad'
import Lunar from '../home/lunar'
import './index.less'

class RootSider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: ''
    }
  }

  componentDidMount() {
    // console.log(this.props)
    const { location } = this.props
    const { pathname } = location

    this.setState({
      pathname
    })

    const adsbygoogle = window.adsbygoogle || []
    adsbygoogle.push({})
    console.log('ads pushed')
  }
  handleCheckPath(name) {
    const { pathname } = this.state
    return pathname.indexOf(name) >= 0
  }
  static getDerivedStateFromProps(props, state) {
    const pathname = props.location.pathname
    // console.log(props, state)
    if (pathname != state.pathname) {
      return {
        ...state,
        pathname,
      }
    }
    return null
  }
  handleRandomContent() {
    const content = []
    if (this.handleCheckPath('article')) {
      content.push(<Quan key='sider-quan' />)
    } else if (this.handleCheckPath('quan') || this.handleCheckPath('/u/')) {
      content.push(<Article key='sider-article' />)
    } else {
      const n = Math.random() * 100
      if (n > 50) {
        content.push(<Quan key='sider-quan' />)
      } else {
        content.push(<Article key='sider-article' />)
      }
    }
    return content
  }
  render() {
    return (
      <div className={ `root-sider ` }>
        {
          this.handleCheckPath('gpt') ? false : <GPT />
        }
        <Lunar showDate={ false } count={ 3 } />
        { this.handleRandomContent() }
        <div className='gads'
        dangerouslySetInnerHTML={{
          __html:
`
<!-- 文章列表侧边 -->
<ins class="adsbygoogle"
     style="display:block; height: 2.85rem; width: 2.85rem"
     data-ad-client="ca-pub-9473934467006481"
     data-ad-slot="8332584575"
     data-full-width-responsive="true"></ins>
`
}}>
        </div>
      </div>
    )
  }
}

export default RootSider
