import React, { useEffect, useRef } from 'react'
import { Input, Slider, Tooltip, Popconfirm } from 'antd'
import MyIcon from 'component/my-icon'
import './index.less'

const { TextArea } = Input

class Creator extends React.Component {
  constructor(props) {
    super(props)
    const { item, config } = props
    const { word } = item
    
    this.state = {
      word,
      config,
    }
  } 
  // shouldComponentUpdate(nextProps, nextState) {
  //   const { item, config } = nextProps
  //   const { word } = item
    
  //   const { word: oldWord, config: oldConfig } = this.state

    
  //   if (word !== oldWord) {
  //     console.log(word, oldWord)
  //     this.setState({
  //       word,
  //       config,
  //     })
  //     return true
  //   }
  //   return false
  // }
  handleSetCanvas(update) {
    const { item, canvas } = this.props
    const { text } = item
    text.set(update)
    canvas.renderAll()
  }
  handleChange(e) {
    const { canvas, index, item, onChange } = this.props 
    const word = e.target.value
    this.setState({
      word
    }, () => {
      this.handleSetCanvas({ text: word })
    })
  }
  handleChangeColor(e) {
    const { config } = this.state
    const color = e.target.value
    this.setState({
      config: {
        ...config,
        fill: color
      }
    })
    this.handleSetCanvas({ fill: color })
  }
  handleChangeStrokeColor(e) {
    const { config } = this.state
    const color = e.target.value
    this.setState({
      config: {
        ...config,
        stroke: color
      }
    })
    this.handleSetCanvas({ stroke: color })
  }
  handleStrokeWidthChange(value) {
    const { config } = this.state
    const width = value
    this.setState({
      config: {
        ...config,
        strokeWidth: width
      }
    })
    this.handleSetCanvas({ strokeWidth: width })
  }
  handleChangeBloder() {
    const { config } = this.state
    const { fontWeight } = config
    this.setState({
      config: {
        ...config,
        fontWeight: fontWeight === 'normal' ? 'bold' : 'normal'
      }
    })
    this.handleSetCanvas({ fontWeight: config.fontWeight === 'normal' ? 'bold' : 'normal' })
  }
  handleChangeFontStyle() {
    const { config } = this.state
    const { fontStyle } = config
    this.setState({
      config: {
        ...config,
        fontStyle: fontStyle === 'normal' ? 'italic' : 'normal'
      }
    })
    this.handleSetCanvas({ fontStyle: config.fontStyle === 'normal' ? 'italic' : 'normal' })
  }
  handleDel() {
    const { index, onDelete, item } = this.props 
    const { text, id } = item
    onDelete(text, id)

  }
  //fontStyle: 'italic'
  handleFocus() {
    const { onFocus, item } = this.props 
    const { text } = item
    onFocus(text)
  }
  render() {
    const { word, config } = this.state
    const { value, index, onFocus, onChange } = this.props
    const { strokeWidth } = config
    return (
      <div className='meme-creator-item-text'>
        <TextArea value={ word } 
                  onFocus={ this.handleFocus.bind(this) } 
                  onChange={ this.handleChange.bind(this) } 
                  autoSize
                  allowClear
                  />
        <div className='meme-creator-item-text-action'>
          <div className='meme-creator-item-text-action-item'>
            <span><MyIcon type='icon-zitiyanse' /></span>
            <Tooltip title='文字前景色'>
              <input type='color' 
                   opacity='true'
                   value={ config.fill } 
                   onChange={ this.handleChangeColor.bind(this) }  
                   />
            </Tooltip>
          </div>

          <div className='meme-creator-item-text-action-item'>
            <span><MyIcon type="icon-icon_c_zitiyinying" /></span>
            <Tooltip title='文字背景色'>
              <input type='color' 
                     opacity='true'
                     value={ config.stroke } 
                     onChange={ this.handleChangeStrokeColor.bind(this) }  
                    />
            </Tooltip>
          </div>

          <div className='meme-creator-item-text-action-item'>
            <span><MyIcon type="icon-cuxi-4" /></span>
            <div className='meme-creator-item-text-action-item-slider'>
              <Slider min={1} max={20} value={ strokeWidth }
                      onChange={this.handleStrokeWidthChange.bind(this)} />
            </div>
          </div>

          <div className='meme-creator-item-text-action-item icon'>
            <span className={ `icon ${ config.fontWeight === 'bold' ? 'enable' : 'disable' }` } 
                  onClick={ this.handleChangeBloder.bind(this) } >
              <Tooltip title='加粗'><MyIcon type="icon-cuxi" /></Tooltip>
            </span>
          </div>

          <div className='meme-creator-item-text-action-item icon'>
            <span className={ `icon ${ config.fontStyle === 'italic' ? 'enable' : 'disable' }` } 
                  onClick={ this.handleChangeFontStyle.bind(this) } >
              <Tooltip title='斜体'><MyIcon type="icon-xieti" /></Tooltip>
            </span>
          </div>

          <div className='meme-creator-item-text-action-item icon del'>
            <Popconfirm
              title="确定要删除？"
              onConfirm={ this.handleDel.bind(this) }
              onCancel={ () => { console.log('close') }}
              okText="确定"
              cancelText="取消"
            >
              <MyIcon type="icon-shanchu" />
            </Popconfirm>
          </div>


        </div>
      </div>
    )
  }
}
export default Creator