import React from 'react'
import { Message, Button } from 'antd'
import Mask from 'lib/component/mask'
import Clipboard from 'lib/component/copyToClipbord'
import QRCode from 'qrcode'
import { getStore, setStore, get } from 'mulan-lib'
import { trackEvent, processRequest } from 'lib'
import './index.less'

class ActiveBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uid: null
    }
  }
  componentDidMount() {
    const userinfo = getStore('userinfo')

    if (userinfo && userinfo.uid) {
      this.setState({
        uid: userinfo.uid,
      })
    }
    const productList = getStore('productList', 'session')
    if (!!productList) {
      this.setState({
        productList,
      })
    } else {
      this.handleGetProduct()
    }
  }
  handleGetProduct() {
    processRequest(
      get('/api/admin/pay/product'),
      data => {
        const productList = data //.slice(1)
        this.setState({
          productList,
        })
        setStore('productList', productList, 'session')
        // console.log(data)
      }
    )
  }
  handleCopyCompleted() {
    trackEvent('gpt', 'copy_r_link')
    Message.success('复制成功，快推荐给朋友吧！')
  }
  handleClose() {
    Mask.closeAll()
  }
  handleCloseQrcode() {
    const { closeQrcodeMask } = this.state
    closeQrcodeMask()
  }
  handleCompletedPay() {
    const { aoid } = this.state
    Message.loading('确认中...', 0)
    setTimeout(() => {
      processRequest(
        get('/api/admin/pay/status', { oid: aoid }),
        data => {
          const { status = '' } = data

          if (status == 'payed' || status == 'success') {
            const { onFetchScore } = this.props
            Mask.closeAll()
            onFetchScore()
            Message.destroy()
            Message.success('恭喜你，充值成功')
          } else {
            Message.destroy()
            Message.warn('暂未完成支付，请稍后再试')
          }
        }
      )
    }, 2000)
  }
  handlePopQrcode() {
    const { qrcode } = this.state
    Message.destroy()
    const that = this
    const closeMask = Mask(
      <div className='qrcode'>
        <img src={ qrcode } className='qrcode-content' />
        <div className='qrcode-tips'>微信扫一扫，进行支付</div>
        <div className='qrcode-action flex flex-justify-between flex-align-center'>
          <Button type='default' onClick={ that.handleCloseQrcode.bind(that) }>退出支付</Button>
          <Button type='primary' onClick={ that.handleCompletedPay.bind(that) }>已完成支付</Button>
        </div>
      </div>
    )
    this.setState({
      closeQrcodeMask: closeMask
    })

  }
  handleProductClick(pid) {
    // console.log(pid)
    const { uid } = this.state
    Message.loading('加载中', 0)
    if (!!uid) {
      processRequest(
        get('/api/admin/pay/native', { id: pid, uid }),
        data => {

          const { status = '', info = {}, aoid } = data
          const { qr = '' } = info
          if (status === 'ok' && !!qr) {
            console.log(aoid)
            QRCode.toDataURL(qr, (err, url) => {
              this.setState({
                aoid,
                qrcode: url
              }, this.handlePopQrcode.bind(this))
            })
          } else {
            Message.warn('下单失败，请重试')
          }
        }
      )
    } else {
      Message.warn('未登录，请刷新重试')
    }
  }
  render() {
    const { uid, productList = [] } = this.state

    return (
      <div className={ `active-box` } >
        <div className='active-box-content'>
          <img src={ require('img/active/popup4.png') } />
          <Clipboard value={ `Hey~邀请你试试「文案·GPT」这个产品，可以生成各种类型的创意文案！https://www.wenanmen.com/gpt?r=${ uid } #文案门` }
                     completeCopy={ this.handleCopyCompleted.bind(this) }>
          <div className='active-box-content-copy' />
          </Clipboard>
          <div className='active-box-content-close' onClick={ this.handleClose.bind(this) } />
          <div className='active-box-content-action'>
            {
              productList.map(({ id, name }) => {
                return (<div key={id} className='active-box-content-action-item' onClick={ this.handleProductClick.bind(this, id) } ></div>)
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ActiveBox
