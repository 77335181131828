import React, { useEffect, useRef } from 'react'
import { Input, Slider, Tooltip, Popconfirm } from 'antd'
import MyIcon from 'component/my-icon'
import './index.less'

const { TextArea } = Input

class Creator extends React.Component {
  constructor(props) {
    super(props)
    const { item, config } = props
    const { src } = item
    this.state = {
      src,
      config,
    }
  } 
  handleSetCanvas(update) {
    const { item, canvas } = this.props
    const { img } = item
    img.set(update)
    canvas.renderAll()
  }

  handleChangeAngle() {
    const { config } = this.state
    const { angle } = config
    const newAngle = angle + 90

    this.setState({
      config: {
        ...config,
        angle: newAngle > 360 ? 0 : newAngle
      }
    })
    this.handleSetCanvas({ angle: newAngle > 360 ? 0 : newAngle})
  }
  handleChangeHMirror() {
    const { config } = this.state
    const { flipX } = config
    console.log(flipX)
    this.setState({
      config: {
        ...config,
        flipX: !flipX
      }
    })
    this.handleSetCanvas({ flipX: !flipX })
  }
  handleChangeVMirror() {
    const { config } = this.state
    const { flipY } = config
    this.setState({
      config: {
        ...config,
        flipY: !flipY
      }
    })
    this.handleSetCanvas({ flipY: !flipY })
  }
  handleDel() {
    const { index, onDelete, item } = this.props 
    const { img, id } = item
    onDelete(img, id)

  }
  handleFocus() {
    const { onFocus, item } = this.props 
    const { img } = item
    onFocus(img)
  }
  render() {
    const { config } = this.state
    const { item, index, onFocus, onChange } = this.props
    const { src } = item
    const { strokeWidth } = config
    return (
      <div className='meme-creator-item-image' >
        <div className='meme-creator-item-image-content' onClick={ this.handleFocus.bind(this) }>
          <img src={ src } />
        </div>
        <div className='meme-creator-item-image-action'>
          <div className='meme-creator-item-image-action-item icon'>
            <span className={ `icon` } 
                  onClick={ this.handleChangeAngle.bind(this) } >
              <Tooltip title='旋转'><MyIcon type="icon-icon_tupianxuanzhuan" /></Tooltip>
            </span>
          </div>

          <div className='meme-creator-item-image-action-item icon'>
            <span className={ `icon ${ config.flipX === 'true' ? 'enable' : 'disable' }` } 
                  onClick={ this.handleChangeHMirror.bind(this) } >
              <Tooltip title='水平镜像'><MyIcon type="icon-xuanzhuan" /></Tooltip>
            </span>
          </div>
          <div className='meme-creator-item-image-action-item icon'>
            <span className={ `icon vertical ${ config.flipX === 'true' ? 'enable' : 'disable' }` } 
                  onClick={ this.handleChangeVMirror.bind(this) } >
              <Tooltip title='垂直镜像'><MyIcon type="icon-xuanzhuan" /></Tooltip>
            </span>
          </div>

          <div className='meme-creator-item-image-action-item icon del'>
            <Popconfirm
              title="确定要删除？"
              onConfirm={ this.handleDel.bind(this) }
              onCancel={ () => { console.log('close') }}
              okText="确定"
              cancelText="取消"
            >
              <MyIcon type="icon-shanchu" />
            </Popconfirm>
          </div>


        </div>
      </div>
    )
  }
}
export default Creator