import React from 'react'
import { Icon } from 'antd'
import { browserHistory } from 'react-router'
import './index.less'

class Emoji extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {}
  render() {
    return (
      <div className={ `extension` }>
        <a href='https://www.wenanmen.com/pages/emoji.html' target='_blank' className='flex flex-justify-between flex-align-center'>

          <div className='extension-title'>
            Emoji 🙈🙉🙊
          </div>

          <div className='extension-tips'>
            <div>全表情</div>
            <Icon type="swap-right" />
          </div>

        </a>
      </div>
    )
  }
}

export default Emoji
