import React from 'react'
import { Icon } from 'antd'
import { browserHistory } from 'react-router'
import { get, moment } from 'mulan-lib'
import BlockTitle from 'lib/component/block-title'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Lunar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      currentLunar: 0,
      bgColor: '#FFF',
      lunarList: [],
    }
  }
  componentDidMount() {
   
    this.handleGetList()

    // console.log(moment('MM')())
    // this.setState({
    //   bgColor: colorList[~~moment('MM')() - 1]
    // })
  }
  handleGetList() {
    // this.handleReset()
    
    if (this.state.lunarList.length === 0) {
      processRequest(
        get('/api/admin/common/lunar/next', { range: 3 }),
        data => {
          // console.log(data)
          this.setState({
            lunarList: data
          })
        }
      )
    }
    
  }
  handleToLunar(lunar, isMore) {
    if (isMore) {
      trackEvent('main', 'lunar')
      browserHistory.push('/lunar')
    } else {
      const { tags } = lunar
      trackEvent('main', 'lunar-kw')
      window.open(`/kw?tags=${ tags }`)
    }
  }

  render() {
    const { loading = true, bgColor, lunarList = [], currentLunar } = this.state
    const { showDate = true, count = 5, className } = this.props
    // console.log(showDate)
    return (
      <div className={ `lunar ${ loading  ? 'loading' : '' } ${ className }` }>
        {
          lunarList.length > 0 && lunarList.slice(0, count).concat({ name: '更多节日' }).map(lunar => {
            const { name, date = '' } = lunar
            const isMore = name === '更多节日'
            return (
                <div key={ name } onClick={ () => this.handleToLunar(lunar, isMore) } className={`lunar-content ${ isMore ? 'more' : '' }`}>
                  <div className=' flex flex-justify-between flex-align-center'>
                    <div className='lunar-content-name'>
                      <span>{ name }</span>
                      { (isMore || !showDate) ? '' : moment('MM/DD')(date) }
                    </div>
                    <div className='lunar-content-tips'><Icon type="swap-right" /></div>
                  </div>
                </div>
            )
          })
        }

      </div>
    )
  }
}

export default Lunar


// {
//   nextLunar.length > 0 && (
//     (() => {
//       const { name, date } = nextLunar[currentLunar]
//       return (
//         <div className='lunar-content flex flex-justify-between flex-align-center'>
//           <div></div>
//           <div>
//             <div className='lunar-content-name'>{ name }</div>
//             <div className='lunar-content-date'>{ moment('MM/DD')(date) }</div>
//           </div>
//           <div></div>
//         </div>
//       )
//     })()
//   )
// }