import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover } from 'antd'
import { send, moment } from 'mulan-lib'
import { browserHistory } from 'react-router'
import BlockTitle from 'lib/component/block-title'
import ArticleItem from 'lib/component/article-item'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest, setTitle } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    setTitle('文案门—隐私条例')
  }
  componentDidMount() {
  }
  render() {
    const { loading = true, hasMore } = this.state
    return (
      <div className={ `privacy-policy ${ loading  ? 'loading' : '' }` }>
        <h1>隐私条例 (Privacy policy )</h1>
        <div>
          <h2>介绍</h2>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
          {
            `
我们将用户的信息安全和隐私作为我们的生命线。基于我们的基本原则“用户需求是我们的首要任务”，我们致力于提高信息流程的透明度，尊重您对数据的控制，保障您的信息和通信安全。

我们遵守以下隐私保护原则，致力于为您提供更安全可靠的服务：

1.安全保障与信赖。我们尽最大努力通过有效合理的信息安全技术和管理流程来防止您的信息遭到破坏，损坏或丢失;
2.选择。我们为您提供方便的隐私管理选项，以便您可以做出适当的选择来控制或管理您的隐私;
3.保护通信秘密。我们将严格按照适用的法律法规保护您的沟通秘密;
4.合理性和必要性。我们将收集必要的数据，以便我们为您和其他用户提供更好的服务，以实现产品的功能。
5.透明度。我们努力以简单直接的方式介绍我们的隐私政策，并帮助您更好地了解我们的信息流程实践;
6.设计隐私。我们将遵循“设计隐私”的理念，并在产品或服务开发的各个阶段考虑隐私，包括法律，产品和设计等多种因素。

本隐私政策主要解释如下：

我们收集了哪些信息。
我们如何使用这些信息。
你的权利。

我们希望您阅读本隐私政策（本“隐私政策”），以了解我们如何收集和使用信息，以便您进一步了解我们的服务并做出适当的选择。
使用我们服务即表示您同意本政策中的内容。除非本文另有规定，否则本隐私政策中使用的术语与我们服务协议中定义的含义相同。
如果您有任何疑问，请联系我们。
            `
          }
          </pre>


          <h2>我们收集的信息</h2>
          <pre style={{ whiteSpace: 'pre-wrap' }}>{`
基于合法，公平和必要的原则，我们只收集为实现产品功能所必需的以下信息。

1.1您提供给我们的信息
1.1.1您在注册帐户时向我们提供的信息。例如，您在注册帐号时提供的昵称和电话号码。
1.1.2使用我们的服务上传的信息。例如，您使用账号共享上传的个人资料图片和图片。
1.1.3您通过我们的客户服务或参与我们的活动提供的信息。例如，您在参与我们的在线活动时填写的问卷可能包括您的姓名，联系电话，家庭住址或其他相关信息。我们的某些服务可能需要您提供所选的敏感个人信息以实现特定功能。如果您选择不提供此类信息，则可能无法在服务中使用特定功能，但不会影响其他功能的使用。如果您自愿提供敏感信息，则表示您同意我们根据本政策中提供的目的和方法处理您的个人敏感信息。
1.2我们在您使用我们的服务时可能收集的信息
1.2.1服务器日志。当您使用我们的服务时，我们可能会自动收集和存储相关信息作为服务日志。?
1）设备信息。例如，设备型号，操作系统版本，唯一设备标识符（“UDID”），电池，信号强度和其他信息。
2）软件信息。例如，软件版本和浏览器类型。为确保操作环境的安全性或提供必要的服务，我们将收集有关您使用移动应用程序和其他软件的信息。
3）IP地址。
4）服务日志。例如，使用我们的服务进行搜索或查看时收集的信息，崩溃日志信息，推荐的站点和其他信息。
5）通信日志。例如，您与之通信的帐户，使用我们的通信服务的时间和期限。
1.2.2位置数据。当您使用与位置相关的服务时，我们可能会收集您设备的位置数据，以便为您提供相关服务。?
1）当您使用这些服务时，我们可能会通过IP地址，GPS，Wi-Fi或基站收集您的地理位置数据;
2）您或其他用户在使用服务时提供的地理位置数据。例如，您帐户中详细说明的位置数据，或您或其他用户共享的照片中包含的地理标志;
1.2.3其他相关信息。为了更好地使用我们的产品或服务，我们将收集相关信息，例如，朋友列表，组列表信息和声纹。此外，如果您选择启用“导入联系人”功能，我们将对您的联系人的姓名和电话号码进行加密并收集加密信息，以便将您与也知道也使用我们服务的人联系起来。
1.3您的信息包含在其他用户共享的信息中。例如，其他用户发布的照片??或视频可能包含您的信息。
1.4来自第三方合作伙伴的信息。

我们可能会与第三方合作伙伴合作。因此，我们可能会获取您使用第三方服务时共享或生成的信息。例如，当您使用帐户登录第三方服务时，我们将知道此类服务的名称和您的登录时间，以便您方便地管理授权。请仔细阅读第三方服务用户协议或隐私政策。
我们如何使用我们收集的信息

根据适用的法律法规或与用户的协议，我们可以通过以下方式使用收集的信息。如果我们将您的信息用于超出以下目的的目的，我们将再次向您解释并征求同意。

2.1为您提供我们的服务。
2.2满足您的个人需求。例如，语言设置，位置设置和其他个性化服务。
2.3产品开发和优化。例如，当我们的系统出现故障时，我们将记录和分析系统崩溃时产生的信息，以进一步优化我们的服务。
2.4确保安全。例如，我们将使用您的信息进行身份验证，安全预防措施，反欺诈监控，存储和备份，客户安全服务和其他应用程序。例如，您下载或安装的安全软件将检测恶意程序或病毒，或识别欺诈性信息。?
2.5推荐您可能感兴趣的广告，新闻等。
2.6评估和改善广告和其他促销活动在我们服务中的效果。
2.7管理软件。例如，软件认证和软件更新等。?
2.8包括您参与有关我们产品和服务的调查活动。

根据适用的法律法规，为了向您提供更好的用户体验并改善我们的服务，或经您同意，我们可能会将从某些服务收集的信息应用于我们提供的其他服务。例如，我们可能会使用您在使用我们的某些服务时收集或生成的信息来向您显示个性化内容或广告，以及用户分析和统计服务。
为确保我们服务的安全性，并帮助我们进一步了解应用程序的性能状态，我们可能会记录相关信息，例如您的使用频率，崩溃日志信息，总体使用情况，性能数据和应用程序来源。我们不会将移动分析软件中存储的信息与您在移动应用程序中提交的个人身份信息相关联。
我们如何使用Cookie和相关技术

我们和我们的第三方合作伙伴可以通过安全插入的Cookie和相关技术收集您的信息，以便为您提供更加个性化的用户体验和服务。我们将严格要求此类第三方合作伙伴遵守本政策的相关要求。
您可以通过浏览器设置设置或管理Cookie。但请注意，您可能无法享受到最佳服务体验，如果您停止使用Cookie，则可能无法使用某些服务。如果您希望了解有关Cookie安全性的更多信息，请参阅Cookie政策声明。
您分享的信息

我们的服务可能允许您与您的朋友和家人以及其他用户分享您的相关信息。例如，您可以在“微信时刻”中公开分享文字和图片。请注意，此类信息可能包含敏感信息，包括您的个人身份信息和个人财产信息。请谨慎考虑披露您的相关个人敏感信息。
您可以控制您共享的信息范围，也可以使用服务设置或根据我们的指导删除公开共享的信息。请注意，此类信息可能仍由我们无法控制的其他用户或无关的第三方独立保留。
如何管理自己的信息

5.1在使用我们的服务时，您可以访问，修改和删除您在注册帐户或其他个人信息时提供的信息，或通过通知指南与我们联系。您访问，修改或删除个人信息的范围和方式取决于您使用的服务。例如，当使用地理相关服务时，为了停止共享您的地理信息，您可以通过关闭手机定位功能或根据软件和硬件服务提供商以及通信服务提供商提供的方法来停止它。请仔细阅读相关指南。
5.2我们将仅根据本政策收集和使用您的信息，以实现我们的产品或服务的功能。如果您发现我们收集或使用您的个人信息不符合法律，行政法规或双方协议，您可以要求我们删除此类信息。如果您发现我们收集或存储了您错误的个人信息，您也可以要求我们更正。请通过本隐私政策中列出的联系方式与我们联系。
5.3出于帐户安全目的，我们可能会要求您在允许您访问，修改和删除相关信息之前验证您的身份。
5.4请理解，由于技术限制，法律或法规要求，我们可能无法满足您的所有要求。我们会尽力在合理的时间内回复您的请求。

我们分享的信息

我们将严格按照法律法规限制信息共享，例如：

6.1在事先征得您的同意，我们可能会与我们的数据处理方以外的第三方分享您的个人信息;
6.2出于外部处理的目的，我们可能会与第三方业务合作伙伴（第三方服务提供商，承包商，代理商，广告合作伙伴，应用程序开发商等）共享您的个人信息，例如，发送电子邮件的电信服务提供商和代表我们推送通知，或向我们提供位置服务的地图服务提供商（他们可能不在您所在的司法管辖区内），并要求他们遵循我们的指示，隐私政策以及任何其他相关的保密和安全措施来处理以上信息适用于我们以及用于：
6.2.1为您提供我们的服务;
6.2.2实现“我们如何使用我们收集的信息”部分的目的;
6.2.3根据我们服务协议和本隐私政策的规定履行我们的义务并剥夺我们的权利;
6.2.4理解，维护和改进我们的服务。如果我们与上述第三方共享您的个人信息，我们将使用加密，匿名或其他方式来保护您的信息安全。
6.3随着我们业务的发展，当合并，收购，资产转移或其他交易发生时，我们会通过推送通知或通知通知您这种情况，并继续保护或要求新的控制人继续保护您的个人信息。适用的法律法规和保护标准不低于本隐私政策。
6.4我们可以使用收集的信息进行大数据分析。例如，我们可能会使用收集的信息进行分析，以生成不包含个人身份信息的城市热图或行业洞察报告。我们可能会将其公开并与我们的合作伙伴共享不包含个人身份信息的后处理信息，以了解用户如何使用我们的服务或使公众更好地了解我们服务的整体使用和其他目的。
6.5出于以下目的，我们可能会披露您的个人信息：
6.5.1遵守适用的法律法规;
6.5.2遵守法院判决，裁决或其他法律程序的要求;
6.5.3遵守相关政府机构或其他相关法律权威实体的要求;
6.5.4我们有理由相信我们需要遵守适用的法律法规;
6.5.5实施相关服务协议或本隐私政策，或保护我们的客户，我们自己，我们的关联公司，我们的用户或员工的公共利益，人身安全和财产以及其他合法权利的合理和必要目的。

我们可能发送给您的信息

7.1推送通知
当您使用我们的服务时，我们可能会向您发送电子邮件，短信，新闻或推送通知。您可以按照相关通知取消订阅设备。
7.2服务公告
如有必要，我们可能会向您发送与服务相关的公告（例如，由于系统维护而暂停某些服务）。您可能无法取消这些非促销服务相关的公告。

信息保留的地点和期限

8.1我们存储信息的地方
我们将根据适用的法律法规将用户在中国大陆境内收集的个人信息存储在境内。
8.2信息保留期
一般而言，我们会在最短的时间内保留您的个人信息，以实现收集此类信息的目的。但是，出于以下目的，我们可能会出于合规目的更改保留期限：
8.2.1遵守相关法律法规;
8.2.2遵守法院判决，裁决或其他法律程序的要求;
8.2.3遵守相关政府机构或其他法律权威实体的要求;
8.2.4我们有理由相信我们需要遵守适用的法律法规;
8.2.5实施相关服务协议或本隐私政策，保护公共利益，保护客户，我们自己，关联公司，用户或员工的人身安全和财产以及其他合法权益的合理必要目的。
如果我们的产品或服务的运营终止，我们将以推送通知，公告等形式通知您，并在合理的时间内删除或匿名您的个人信息。

信息安全

我们采取安全措施来保护您的信息免遭丢失，不当使用，未经授权的访问或披露。

9.1我们将严格按照适用的法律法规保护用户的沟通。
9.2我们将在合理的安全标准内工作，并使用各种安全措施来保护信息的安全。例如，我们将使用加密技术（如TLS，SSL），匿名和其他方式来保护您的个人信息。
9.3我们建立了专门的管理，流程和组织系统，以确保信息安全。例如，我们严格限制可以访问信息的人员的范围，并要求他们遵守其保密义务并进行审计。
9.4如果发生个人信息安全事件的违反，我们将启动应急响应计划，以防止此类安全事件的扩大，并以推送通知，公告等形式通知您。
  `}</pre>

<h2>广告</h2>
<pre style={{ whiteSpace: 'pre-wrap' }}>{`
我们可能会使用您的信息在相关网站，应用和其他渠道上为您提供更相关的广告。
您可以在我们的广告中了解更多信息。
`}</pre>
<h2>保护未成年人</h2>
<pre style={{ whiteSpace: 'pre-wrap' }}>{`
我们非常重视保护未成年人的个人信息。根据相关法律法规，如果您是未满18周岁的未成年人，在使用我们服务之前，您需要获得父母或法定监护人的同意。如果您是未成年人的监护人并且对未成年人的个人信息有疑问，请通过下面第13节中提供的联系方式与我们联系。
`}</pre>
<h2>适用范围</h2>
<pre style={{ whiteSpace: 'pre-wrap' }}>{`
我们的所有服务均受本隐私政策的约束。但是，某些服务有自己的特定隐私指导/声明，此类特定隐私指导/声明描述了我们如何更具体地处理您在服务中的信息。如果本隐私政策与特定服务的隐私指导/声明不一致，则以该特定服务的隐私政策为准。
请注意，本隐私政策不适用于其他公司或个人提供的服务。例如，您可以使用微信帐户登录其他公司或个人提供的服务。
您对此类第三方服务的使用受此类第三方服务（而非本隐私政策）的条款和隐私政策的约束，您需要仔细阅读其隐私惯例。
`}</pre>
<h2>关于侵权</h2>
<pre style={{ whiteSpace: 'pre-wrap' }}>{`
网站内容全采集自网络，如果其中包括了您未授权的内容，请联系我们进行删除。
`}</pre>
<h2>联系我们</h2>
<pre style={{ whiteSpace: 'pre-wrap' }}>{`
如果您对本隐私政策或其他相关事宜有任何疑问，请通过 xfcdxg_zq@163.com 与我们联系。
`}</pre>

      </div>
      </div>
    )
  }
}

export default Home

// <div className='article-list-item'>
//   <a href={`//www.wenanmen.com/pages/s/${ item._id }.html`} target='_blank' style={{ color: 'initial' }}>
//     <div className='article-list-item-title'>
//       { item.title }
//     </div>
//     <div className='article-list-item-sub-title'>
//       { item.digest }
//     </div>
//   </a>
//   <div className='article-list-item-create-time'>{ moment('MM/DD')(item.createdTime) }</div>
// </div>
