import React from 'react'
import { get, moment } from 'mulan-lib'
import { Radio, Tooltip } from 'antd'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    const year = moment('YYYY')(Date.now()) 
    this.state = {
      year,
      nextYear: parseInt(year) + 1,
      current: year,
      lunarList: {
        [year]: [],
        [parseInt(year) + 1]: []
      }
    }
  }
  componentDidMount() {
    const { current } = this.state
    this.handleGetList(current)
  }
  handleGetList() {
    // this.handleReset()
    
    if (this.state.lunarList[this.state.current].length === 0) {
      processRequest(
        get('/api/admin/common/lunar', { year: this.state.current }),
        data => {
          // console.log(data)
          const { lunarList } = this.state
          lunarList[this.state.current] = data
          this.setState({
            lunarList,
          })
          console.log(this.state.lunarList)
        }
      )
    }
    
  }
  handleYearChange({ target }) {
    const { value } = target
    this.setState({
      current: value
    }, this.handleGetList.bind(this))

  }
  handleTo(kw) {
    trackEvent('main', 'lunar-kw')
    window.open(`/kw?tags=${ kw }`)
  }
  render() {
    const { year, nextYear, current, lunarList } = this.state
    return (
      <div className='lunar-list'>
        <div className='lunar-list-year'>
          <Radio.Group value={ current } onChange={ this.handleYearChange.bind(this) } >
            <Radio.Button value={ year }>今年</Radio.Button>
            <Radio.Button value={ nextYear }>明年</Radio.Button>
          </Radio.Group>
        </div>
        <div className='lunar-list-content'>
          {
            ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月','九月', '十月', '十一月', '十二月']
            .map((o, i) => {
              return (
                
                  <div key={ o } className='lunar-list-content-month'>
                    <div className='lunar-list-content-month-title'>{ o }</div>
                    <div className='lunar-list-content-month-day'>
                      {
                        lunarList[current].length > 0 && (
                          lunarList[current].filter(lunar => {
                            const { month = '' } = lunar
                            return parseInt(month) === i + 1
                          }).map(lunar => {
                            const { name, date = '', tags, month, cdate } = lunar
                            const isExpired = new Date(date).getTime() < Date.now()
                            const ts = moment('x')(date)
                            const isNear = ts > Date.now() && ts < Date.now()+ 1000 * 60 * 60 * 24 * 15
                            return (
                              <Tooltip key={ name } placement='bottom' title="查看文案">
                                <div className={`lunar-list-content-month-day-item ${ isExpired ? 'expired' : '' } ` } onClick={ this.handleTo.bind(this, tags) }>
                                  { isExpired && (<img src={ require('img/common/gq.png') } />) }
                                  { isNear && (<img class='hot' src={ require('img/common/hot.png') } />) }
                                  <div className='lunar-list-content-month-day-item-name'>{ name }</div>
                                  <div className='lunar-list-content-month-day-item-date'>{ moment('MM/DD')(date) }</div>
                                </div>
                              </Tooltip>
                            )
                          })
                        )
                      }
                    </div>

                  </div>
              )
            })
          }
          </div>
      </div>
    )
  }
}

export default Home
