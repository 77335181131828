import React from 'react'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  componentDidMount() {
    const adsbygoogle = window.adsbygoogle || []
    adsbygoogle.push({})
    console.log('ads pushed')
  }
  render() {
    const { slot } = this.props
    return (
        <div className='gads'
        dangerouslySetInnerHTML={{
          __html:
`
<!-- 文章列表首屏 -->
<ins class="adsbygoogle"
     style="display:block; width: 100%;"
     data-ad-client="ca-pub-9473934467006481"
     data-ad-slot="${ slot }"
     data-full-width-responsive="true"></ins>
`
}}>
        </div>
    )
  }
}
