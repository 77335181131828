import React from 'react'
import BlockTitle from 'lib/component/block-title'
import WenanActions from 'component/wenan-actions'
import { Badge, Button, Radio, Icon, Tooltip, Popover, Switch } from 'antd'
import { send } from 'mulan-lib'
import { processRequest, trackEvent } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      isSelection: true,
    }
  }
  componentDidMount() {
    this.rank()
  }
  checkScroll() {
    const wordEl = this.refs.word
    const scrollHeight = wordEl.scrollHeight
    const clientHeight = wordEl.clientHeight
    if (scrollHeight > clientHeight) {
      wordEl.style.alignItems = 'flex-start'
    }
  }
  rank() {
    trackEvent('roll', 'next')
    const { isSelection } = this.state
    let url = 'wenan/rank'
    if (isSelection) {
      url = 'wenan/selection/rank'
    }
    processRequest(
      send('/api/admin/common/func', {
        url
      }),
      data => {
        setTimeout(() => {
          if (isSelection) {
            const { word, _id, opus, author = '佚名' } = data

            this.setState({
              loading: false,
              word,
              wordId: _id,
              author,
              opus,
              wenan: {
                ...data,
                source: 'selection'
              }
            }, this.checkScroll.bind(this))
          } else {
            const { word, _id, articleTitle = '', articleId = '' } = data
            this.setState({
              loading: false,
              word,
              wordId: _id,
              articleTitle,
              articleId,
              wenan: data
            }, this.checkScroll.bind(this))
          }
        }, 1000)
      }
    )
  }
  roll() {
    this.setState({
      word: '加载中...',
      wordId: '',
      author: '',
      loading: true,
    }, () => {
      this.refs.word.style = ''
      this.rank()
    })
  }
  handleToArticle(articleId) {
    trackEvent('main', 'article-detail')
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/rid',
        data: {
          rid: articleId
        }
      }),
      data => {
        const { _id } = data
        window.open(`https://www.wenanmen.com/pages/s/${ _id }.html`)
      }
    )
  }

  handleSelectionChange({target}) {
    this.setState({
      isSelection: target.value
    }, this.roll.bind(this))
  }
  render() {
    const { word, author = '', wordId, loading, articleId, articleTitle = '', isSelection } = this.state
    return (
      <div className={ `roll` }>
        <BlockTitle title='文案邂逅' secondary noPadding
                    extra={(
                        <span className='flex flex-align-center'>
                          <Radio.Group value={ isSelection } onChange={ this.handleSelectionChange.bind(this) } size="small">
                            <Radio.Button value={ true }>大师精选</Radio.Button>
                            <Radio.Button value={ false }>网络佳作</Radio.Button>
                          </Radio.Group>
                        </span>
                    )} showArrow={ false } />
        <div className='roll-content'>
          <div className='wp100'>
            <div><img src={ require('img/common/l-yin.png') } style={{ width: '.3rem' }} /></div>
            <div className='roll-content-word' ref='word'>
              <div>
                { author && <span className='roll-content-word-author'><a href='#' title={ author }>{ author }</a>：</span> }{ word }
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <img src={ require('img/common/r-yin.png') } style={{ width: '.3rem' }} />
            </div>
          </div>
        </div>
        <div className='roll-action' >
          <div className={ `roll-action-article ${ loading ? 'loading' : '' }` }>
            {
              articleTitle && articleId && (
                <Tooltip placement='top' title={ <span style={{ fontSize: '.1rem' }}>{ `出自『${articleTitle}』` }</span> }>
                  <Button onClick={ this.handleToArticle.bind(this, articleId) } type='ghost' shape="circle" className='roll-action-group-btn' >
                    <span><Icon type='link'  /></span>
                  </Button>
                </Tooltip>
              )
            }
          </div>
          <div className='flex flex-justify-between flex-align-center'>
            <div style={{ marginRight: '.12rem' }}>
              <WenanActions wenan={ this.state.wenan } visible={ !loading } source='roll' />
            </div>
            <Button onClick={ this.roll.bind(this) } loading={ loading } disabled={ loading }
                    type='primary'
                    shape="round" icon='sync'></Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
