import React from 'react'
import {  } from 'antd'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className='warning'>
        <div className='warning-icon'><img src={ require('img/oops.svg') } /></div>
        <div className='warning-title'>访问的页面出现了点问题...</div>
        <div className='warning-option'>没关系，<a href='https://www.wenanmen.com/'>回首页</a>看看吧</div>
      </div>
    )
  }
}

export default Home
