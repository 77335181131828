import React from 'react'
import { Spin } from 'antd'
import { send } from 'mulan-lib'
import WenanCardItem from 'lib/component/wenan-card-item2'
import LoadMore from 'lib/component/load-more'
import { processRequest, trackEvent, setTitle } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 6,
      list: [],
      hasMore: true
    }
    setTitle(`文案圈子`)
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 6,
      list: []
    })
  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
    this.handleGetList()
  }
  handleTransPic(picList, cb) {
    const fileList = picList.reduce((list, curr) => {
      const { pic, thumbnail } = curr
      list.push(pic)
      list.push(thumbnail)
      return list
    }, [])
    //
    processRequest(
      send('/api/admin/common/func', {
        url: 'event/trans/pic',
        data: {
          fileList,
        }
      }),
      data => {
        cb(data || [])
      }
    )
  }
  handleGetList() {
    // this.handleReset()
    processRequest(
      send('/api/admin/common/func', {
        url: 'event/list',
        data: {
          from: this.state.from,
          max: this.state.max,
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        const picList = data.filter(o => (o.type === 'picture'))

        if (picList.length === 0) {
          this.setState({
            loading: false,
            list: list.concat(data || []),
          })
        } else {
          this.handleTransPic(picList, reply => {
            // console.log(reply)
            const picList = reply.reverse()
            this.setState({
              loading: false,
              list: list.concat(data.map(o => {
                if(o.type === 'picture') {
                  o.pic = picList.pop()
                  o.thumbnail = picList.pop()
                }
                return o
              })),
            })
          })
        }

      }
    )
  }
  //
  // handleToUserPage(uid) {
  //   trackEvent('quan', 'user-center-other')
  //   browserHistory.push(`/u/${ uid }`)
  // }
  handleInfiniteOnLoad = () => {
    trackEvent('quan', 'next')
    let { list, from, max, updateCount } = this.state
    this.setState({
      loading: true,
      from: from + max
    }, this.handleGetList.bind(this))
  }

  render() {
    const { loading = true, hasMore, list = [] } = this.state
// <div className='quan-item-content-pic' ><img src={thumbnail} /></div>
    return (
      <div className={ `quan-list ${ loading  ? 'loading' : '' }` }>
        <h1 className='child-page-title'>文案圈子</h1>
        <div className='quan-list-content'>
          {
            (!loading || list.length > 0) && (
              list.map(o => {
                return (
                  <WenanCardItem key={ o._id } obj={o} />
                )
              })
            )
          }
          <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } />
        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

export default Home


// <List
//   dataSource={list}
//   grid={{ gutter: 14, lg:3, md: 1, sm:1, xs:1 }}
//   loadMore={ <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } /> }
//   renderItem={item => (
//     <List.Item style={{ width: '100%' }} >
//       <div className='quan-list-content-card'>
//         <WenanCardItem randomColor={ true } { ...item } style={{ height: '100%' }} showBorder={ false } animation={ true }  />
//       </div>
//     </List.Item>
//   )}
// >
// </List>
