import React from "react"
import { Router, Route, browserHistory, IndexRoute } from "react-router"

import App2 from "./v2/home"
import Root2 from "./root"

import Articles from "./v2/articles"
import Quan from "./v2/quan"
import Keywords from "./v2/keywords"
import User from "./v2/user"
import Poster from "./v2/poster"
import Warning from "./v2/warning"
import Parsing from './v2/parsing'
import GPT3 from './v2/gpt3'
import AI from './v2/ai'
import Tools from './v2/tools'
import Lunar from './v2/tag/lunar'
import Memes from './v2/memes'
import PrivacyPolicy from "./v2/privacy-policy"

import MEMENew from "./v2/memes/new";
import MEMEFree from "./v2/memes/free";

import Layout2 from "./v2"
// <Route path="gpt" component={ GPT3 } />
// 路由配置
const Routes = () => (
  <Router history={ browserHistory }>
    <Route path="/" component={Layout2}>
      <IndexRoute component={App2} />
      <Route path="p(/:id)" component={ Parsing } />
      <Route path="articles" component={ Articles } />
      <Route path="quan" component={ Quan } />
      <Route path="kw" component={ Keywords } />

      <Route path="gpt(/:type)" component={ AI } />
      <Route path="warning" component={ Warning } />
      <Route path="u/:uid" component={ User } />
      <Route path="poster/:id" component={ Poster } />
      <Route path="tttt" component={ Tools } />
      <Route path="lunar" component={ Lunar } />
      <Route path="memes" component={ Memes } />

      <Route path="memes/new(/:_id)" component={MEMENew} />
      <Route path="memes/free" component={MEMEFree} />
      <Route path="memes/(:kw)" component={Memes} />
      <Route path="privacy-policy" component={ PrivacyPolicy } />
    </Route>
  </Router>
)
export default Routes
