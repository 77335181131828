import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import { send, setStore, getStore } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Article extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      list: [],
      hasMore: true
    }
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      list: []
    })
  }
  componentDidMount() {
    setTimeout(this.handleGetList.bind(this), 500)
  }
  handleGetList() {
    this.handleReset()
    const storeList = getStore('store-article')
    if (storeList) {
      const { data, ts } = storeList
      if (!!!ts || (ts && (Date.now() - ts) > 60 * 60 * 1000)) {
        localStorage.removeItem('store-article')
        this.handleGetList()
      } else {
        setTimeout(() => {
          this.setState({
            loading: false,
            list: data,
          })
        }, 1000)
      }
    } else {
      processRequest(
        send('/api/admin/common/func', {
          url: 'article/rank',
          data: {
            len: 6
          }
        }),
        data => {
          const { list } = this.state
          setStore('store-article', { data, ts: Date.now() })
          this.setState({
            loading: false,
            list: list.concat(data || []),
          })
        }
      )
    }
  }

  render() {
    const { loading = true } = this.state
    return (
      <div className={ `article-sider ${ loading  ? 'loading' : '' }` } >
        <BlockTitle title="推荐文章" extra='更多' secondary noPadding onClick={
                      () => {
                        trackEvent('sider', 'articles')
                        browserHistory.push('/articles')
                      }
                    } />
        <div className='article-sider-content'>
          {
            loading && (
              [1,2,3].map(o => {
                return (
                  <div key={`article-sider-skeleton-${ o }`} className='article-sider-item' style={{ marginBotton: '.12rem' }}>
                    <Skeleton title={false} loading={ loading } active />
                  </div>
                )
              })
            )
          }
          {
            !loading && (
              this.state.list.map(item => {

                return (
                  <div key={ item._id } className='article-sider-item'>
                    <a title={ item.title } href={`//www.wenanmen.com/pages/s/${ item._id }.html`} target='_blank' style={{ color: 'initial' }} className='article-sider-item-title'>
                      👉 { item.title }
                    </a>
                  </div>
                )
              })
            )
          }

        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}


      </div>
    )
  }
}

export default Article
