import React from 'react'
import { Button, Modal, Input } from 'antd'
import MyIcon from 'component/my-icon'
import { send } from 'mulan-lib'
import fabric, { handleLockMoving } from './utils/fabric'
import { saveImg, localImageToBase64 } from './utils/img'
import { textConfig, imgConfig } from './utils/config'
import { processRequest } from 'lib'
import Text from './text'
import Image from './image'
import './index.less'

const { TextArea } = Input
const ButtonGroup = Button.Group

class Creator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: []
    }
    this.textConfig = textConfig
    this.imgConfig = imgConfig
    this.canvasEL = null
    this.fileInputRef = React.createRef();
  }
  handleCreatePNG() {
    const { id } = this.props
    const pngurl = this.canvasEL.toDataURL({
      format: 'jpeg',
      multiplier: 2,
      quality: 1
    })
    this.handleHot(id)
    this.handlePopPic(pngurl)
  }
  handleHot(id) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/hot',
        data: {
          id
        }
      }),
      data => {
        console.log(id, '已添加热度')
      }
    )
  }

  handlePresetCanvas(image) {
    const canvasSize = {
      w: 400,
      h: 500,
    }
    const clientWidth = window.document.documentElement.clientWidth
    if (clientWidth < 1000) {
      canvasSize.w = clientWidth
      canvasSize.h = clientWidth
    }
    fabric.Image.fromURL(image, replyImage => {
      // console.log(replyImage)
      // 计算图片的宽高比
      const aspectRatio = replyImage.width / replyImage.height;

      const scaleX = canvasSize.w / replyImage.width
      const scaleY = canvasSize.w / parseFloat(aspectRatio) / replyImage.height

      this.canvasEL = new fabric.Canvas('canvas', {
        backgroundColor: '#000',
        width: canvasSize.w,
        selection: false,
        allowTouchScrolling: true,
        // scale: 2
      })
      this.canvasEL.setBackgroundImage(replyImage, () => {
        this.canvasEL.renderAll()
      }, {
        scaleX,
        scaleY,
        imageSmoothing: true
      })
      this.canvasEL.setWidth(replyImage.getScaledWidth())
      this.canvasEL.setHeight(replyImage.getScaledHeight())

      handleLockMoving(this.canvasEL)
      // initAligningGuidelines(this.canvasEL)
    }, { crossOrigin: 'anonymous' })
  }
  handleLoadFromJSON(json) {
    this.canvasEL = new fabric.Canvas('canvas', {
      backgroundColor: '#000',
      selection: false,
    })
    const { objects, backgroundImage } = json
    console.log(json)
    fabric.Image.fromURL(backgroundImage.src, replyImage => {
      this.canvasEL.loadFromJSON({...json, objects: []}, () => {
        // this.canvasEL.renderAll()
      })
      this.canvasEL.setBackgroundImage(replyImage, () => {
        this.canvasEL.renderAll()
      }, {
        scaleX: backgroundImage.scaleX,
        scaleY: backgroundImage.scaleY,
        imageSmoothing: true
      })

      this.canvasEL.setWidth(replyImage.getScaledWidth())
      this.canvasEL.setHeight(replyImage.getScaledHeight())
      
      handleLockMoving(this.canvasEL)
      // initAligningGuidelines(this.canvasEL)

      objects.forEach(item => {
        const { type } = item
        if (type === 'text') {
          const { text, ...config } = item
          this.handleCreateText(text, config)
        } else if (type === 'image') {
          const { src, ...config } = item
          this.handleCreateImage(src, config)
        }
      })
    })
  }
  componentDidMount() {
    const { model } = this.props
    if (!!model && (model === 'edit' || model === 'new')) {
      const { pic, presetJSON } = this.props
      if (!!presetJSON) {
        console.log('load json')
        this.handleLoadFromJSON(JSON.parse(presetJSON))
      } else if (!!pic) {
        console.log('load pic')
        this.handlePresetCanvas(pic)  
      }
    }
  }
  handleCopyJSON() {
    const { onJSONBTNClick } = this.props
    const jsonData = this.canvasEL.toJSON({ compressed: false })
    const jsonStr = JSON.stringify(jsonData)

    !!onJSONBTNClick && onJSONBTNClick(jsonStr)
  }
  handlePushList(item, type) {
    const { list = [] } = this.state
    list.push({
      ...item,
      type,
      id: Date.now()
    })
    this.setState({
      list
    }, () => {
      // this.canvasEL.renderAll()
    })
  }
  handleCreateText(_word, _config) {
    
    const word = !!_word ? _word : `TEXT#${this.state.list.length + 1}`
    const text = new fabric.Text(word, !!_config ? _config : this.textConfig)

    this.canvasEL.add(text)
    this.handlePushList({
      text,
      word,
    }, 'text')
  }
  handleCreateImage(src, config) {
    fabric.Image.fromURL(src, replyImage => {
      const ratio = replyImage.width / replyImage.height
      const clientWidth = window.document.documentElement.clientWidth
      const rectWidth = clientWidth < 1000 ? 200 : 100
      if (!!config) {
        replyImage.set(config)
      } else {
        replyImage.set({
          ...this.imgConfig,
          scaleX: rectWidth * ratio / replyImage.width,
          scaleY: rectWidth / replyImage.height,
        })
      }
      
      this.canvasEL.add(replyImage)
      this.handlePushList({ src, img: replyImage }, 'image')
    }, { crossOrigin: 'anonymous' })
  }
  handlePopPic(pic) {
    Modal.confirm({
      title: '',
      className: 'pic-modal',
      okText: '下载图片',
      cancelText: '关闭',
      maskClosable: true,
      onOk: () => {
        saveImg(pic)
      },
      content: (
        <div>
          <img src={pic} style={{ width: '100%', boxShadow: '0 0 5px 3px rgba(0,0,0,.05)' }} />
          <div style={{ color: '#ccc', fontSize: '.12rem', textAlign: 'center', marginTop: '.06rem' }}>
            点击「下载图片」，<strong style={{ color: '#999', marginRight: '.03rem'}}>手机端</strong>请长按图片保存
          </div>
        </div>
      ),
      icon: false,
      footer: false
    })
  }

  handleSelectItem(item) {
    this.canvasEL.discardActiveObject()
    this.canvasEL.setActiveObject(item)
    this.canvasEL.renderAll()
  }
  handleDeleteItem(item, id) {
    const { list } = this.state
    this.canvasEL.remove(item)
    this.canvasEL.renderAll()
    this.setState({
      list: list.filter((item) => item.id !== id)
    })
  }
  openFileDialog = () => {
    if (this.fileInputRef.current) this.fileInputRef.current.click();
  }
  handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      localImageToBase64(file, base64 => {
        this.handleCreateImage(base64)
      })
    }
    event.target.value = ''
  }
  handleClearAll() {
    const { list } = this.state
    list.forEach(item => {
      const { text, img, type } = item
      if (type === 'text') {
        this.handleDeleteItem(text)
      } else if (type === 'image') {
        this.handleDeleteItem(img)
      }
    })
    this.setState({
      list: []
    })
  }
  render() {
    const { pic = '', showJSONBTN = false } = this.props
    const { list = [] } = this.state
    return (
      <div className='meme-creator'>
        <div className='meme-creator-content'>
          <div className='meme-creator-content-preview'>
            <canvas id='canvas' width={400} />
          </div>
          <div className='meme-creator-content-tools' >
            <div className='meme-creator-content-tools-action' >
              <ButtonGroup>
                <Button type='ghost' onClick={() => this.handleCreateText()}>
                  <MyIcon type="icon-tianjiawenzi" />文字
                </Button>
                <Button type='ghost' onClick={this.openFileDialog.bind(this)}>
                  <MyIcon type="icon-tianjiatupian_huaban" />图片
                  <input
                    type="file"
                    accept="image/*"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
                </Button>
                
              </ButtonGroup>
              <div className='meme-creator-content-tools-action-tips'>注：双击按钮传图，PC端体验更加</div>
            </div>
            <div className='meme-creator-content-tools-container' >
              <div className='meme-creator-content-tools-container-img' >
              {
                list.filter(({ type }) => type === 'image').map((item, index) => {
                  const { type, id } = item
                  return (
                    <div className='meme-creator-content-tools-container-item' key={id}>
                      <Image item={item}
                            index={index}
                            config={this.imgConfig}
                            canvas={this.canvasEL}
                            onFocus={this.handleSelectItem.bind(this)}
                            onDelete={this.handleDeleteItem.bind(this)}
                          />
                    </div>
                  )
                })
              }
              </div>
              {
                list.length === 0 && (
                  <div className='meme-creator-content-tools-container-empty'>
                    <p>请添加文字 / 图片</p>
                  </div>
                )
              }
              {
                list.filter(({ type }) => type === 'text').map((item, index) => {
                  const { type, id } = item
                  return (
                    <div className='meme-creator-content-tools-container-item' key={id}>
                       <Text item={item}
                          index={index}
                          config={this.textConfig}
                          canvas={this.canvasEL}
                          onFocus={this.handleSelectItem.bind(this)}
                          onDelete={this.handleDeleteItem.bind(this)}
                        />
                    </div>
                  )
                })
              }
            </div>

            <div className='meme-creator-content-tools-footer'>
              <ButtonGroup>
                <Button type='ghost' onClick={this.handleCreatePNG.bind(this)}>
                  <MyIcon type="icon-xiazai-L" />下载
                </Button>
                <Button type='ghost' onClick={this.handleClearAll.bind(this)}>
                  <MyIcon type="icon-qingchu" />清除
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>

        

      </div>
    )
  }
}
export default Creator