import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import { send, getStore, setStore } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import UserAvatar from 'lib/component/user-avatar'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Article extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      list: [],
      hasMore: true
    }
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      list: []
    })
  }
  componentDidMount() {
    setTimeout(this.handleGetList.bind(this), 500)
  }
  handleGetList() {
    this.handleReset()
    const storeList = getStore('store-quan')
    if (storeList) {
      const { data, ts } = storeList
      if (!!!ts || (ts && (Date.now() - ts) > 60 * 60 * 1000)) {
        localStorage.removeItem('store-quan')
        this.handleGetList()
      } else {
        setTimeout(() => {
          this.setState({
            loading: false,
            list: data,
          })
        }, 1000)
      }
    } else {
      processRequest(
        send('/api/admin/common/func', {
          url: 'event/list',
          data: {
            from: 0,
            max: 6
          }
        }),
        data => {
          const { list } = this.state
          setStore('store-quan', { data, ts: Date.now() })
          this.setState({
            loading: false,
            list: list.concat(data || []),
          })
        }
      )
    }
  }
  handleToUserPage(uid) {
    trackEvent('sider', 'user-center-other')
    browserHistory.push(`/u/${ uid }`)
  }
  render() {
    const { loading = true } = this.state
    return (
      <div className={ `quan-sider ${ loading  ? 'loading' : '' }` } >
        <BlockTitle title="圈子动态" extra='更多' secondary noPadding onClick={
                      () => {
                        trackEvent('sider', 'quan')
                        browserHistory.push('/quan')
                      }
                    } />
        <div className='quan-sider-content'>
          {
            loading && (
              [1,2,3].map(o => {
                return (
                  <div key={`quan-sider-skeleton-${ o }`} className='quan-sider-item' style={{ marginBotton: '.12rem' }}>
                    <Skeleton title={false} loading={ loading } active />
                  </div>
                )
              })
            )
          }
          {
            !loading && (
              this.state.list.map(item => {
                const { uid, userinfo, wenan } = item
                const { nickname, headimgurl } = userinfo
                const { word } = wenan
                return (
                  <div className='quan-sider-item' key={ word }>
                    <UserAvatar nickname={ nickname } avatar={ headimgurl } tips='查看足迹' showSource={ false } onClick={ this.handleToUserPage.bind(this, uid) }  size='small'  />
                    <div title={ word } className='quan-sider-item-title'>
                      { word }
                    </div>
                  </div>
                )
              })
            )
          }

        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}


      </div>
    )
  }
}

export default Article
