import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover } from 'antd'
import { browserHistory } from 'react-router'
import { send } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
  }

  render() {
    const { name, children } = this.props
    return (
      <div className='poster-tools'>
        <div className='poster-tools-content'>
          <div className='poster-tools-content-name'>{ name }</div>
          <div className='poster-tools-content-action'>
            { children }
          </div>
        </div>
      </div>
    )
  }
}

export default Home
