import React from 'react'
import BlockTitle from 'lib/component/block-title'
import WenanActions from 'component/wenan-actions'
import { Badge, Button, Radio, Icon, Tooltip, Popover, Switch } from 'antd'
import { send } from 'mulan-lib'
import { processRequest, trackEvent } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

class Roll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      isSelection: true,
      isMatchWords: false,
    }
  }
  componentDidMount() {
    // this.rank()

    const { keywords = [] } = this.props
    if (keywords.length > 0) {
      this.setState({
        isMatchWords: true
      })
    }
  }
  checkScroll() {
    const wordEl = this.refs.word
    const scrollHeight = wordEl.scrollHeight
    const clientHeight = wordEl.clientHeight
    if (scrollHeight > clientHeight) {
      wordEl.style.alignItems = 'flex-start'
    }
  }
  
  handleToArticle(articleId) {
    trackEvent('main', 'article-detail')
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/rid',
        data: {
          rid: articleId
        }
      }),
      data => {
        const { _id } = data
        window.open(`https://www.wenanmen.com/pages/s/${ _id }.html`)
      }
    )
  }

  handleSelectionChange({target}) {
    this.setState({
      isSelection: target.value
    }, this.roll.bind(this))
  }
  handleMatchKeywords(words) {
    const { keywords } = this.props

    let matched = words.match(new RegExp(keywords.join("|"), "g"))
    matched = [...new Set(matched)]
    // console.log(matched)
    matched.map(o => {
      const rep = `<span class='kw-roll-content-word-light'>${ o }</span>`
      words = words.replace(new RegExp(o, "g"), rep)
    })

    return words
  }
  render() {
    const { isMatchWords } = this.state
    const { wenan = {}, handleRoll, loading, total = 0 } = this.props
    let { word = '加载中...', author = '', wordId, articleId, articleTitle = '', isSelection } = wenan
    // if (loading) {
    //   author = ''
    // }
    return (
      <div className={ `kw-roll` }>
        <div className='kw-roll-content'>
          <div className='wp100'>
            <div className='flex flex-justify-between flex-align-center'>
              <img src={ require('img/common/l-yin.png') } style={{ width: '.3rem' }} />
              {
                !!total && (
                  <span className='kw-roll-content-total'>共<span>{ total }</span>条</span>
                )
              }
            </div>
            <div className='kw-roll-content-word' ref='word'>
              {
                isMatchWords ? (
                  <div dangerouslySetInnerHTML={{ __html: this.handleMatchKeywords(word) }}></div>
                ) : (
                  <div>
                    { author && <span className='kw-roll-content-word-author'><a href='#' title={ author }>{ author }</a>：</span> }{ word }
                  </div>
                )
              }
            </div>
            <div style={{ textAlign: 'right' }}>
              <img src={ require('img/common/r-yin.png') } style={{ width: '.3rem' }} />
            </div>
          </div>
        </div>
        <div className='kw-roll-action' >
          <div className={ `kw-roll-action-article ${ loading ? 'loading' : '' }` }>
            {
              articleTitle && articleId && (
                <Tooltip placement='top' title={ <span style={{ fontSize: '.1rem' }}>{ `出自『${articleTitle}』` }</span> }>
                  <Button onClick={ this.handleToArticle.bind(this, articleId) } type='ghost' shape="circle" className='kw-roll-action-group-btn' >
                    <span><Icon type='link'  /></span>
                  </Button>
                </Tooltip>
              )
            }
          </div>
          <div className='flex flex-justify-between flex-align-center'>
            <div style={{ marginRight: '.12rem' }}>
              <WenanActions wenan={ wenan } visible={ !loading } source='roll' />
            </div>
            <Button onClick={ handleRoll } loading={ loading } disabled={ loading }
                    type='primary'
                    shape="round" icon='sync'></Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Roll
