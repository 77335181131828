import React from 'react'
import { getStore, get, send, stringifyQuery } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import { Switch, Popover, Select, Input, Message, Button, Icon, Alert, Tooltip } from 'antd'
// import GAds from 'lib/component/gads'
// import Mask from 'lib/component/mask'
// import ActiveBox from './active-box'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'

import './index.less'
const { Search } = Input
const { TextArea } = Input

const { Option } = Select
class GPT3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      val: '',
      user_fake_id: this.handleCreateUserFakeId(),//'xkb2rbc03xo9gtaz',
      session_id: `${Date.now()}`,
      answerStatus: 0,
      answerStatusText: '回答中...',
      postDraftStatus: false,
      type: 1,
      // loading: true,
      feel: 1,
      score: 0,
      scoreCost: 15,
      feelings: [
        '无',
        '开心',
        '兴奋',
        '激动',
        '平淡',
        '悲伤',
        '低落',
        '愤怒',
      ]
    }
    this.answerIndex = -1
    this.timer = null
    this.popTimer = null
  }
  handleCreateUserFakeId() {
    const defCharArray = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let count = 0
    let str = ''
    while(count++ < 16) {
      str = str.concat(defCharArray[~~(Math.random() * defCharArray.length)])
    }
    return str
  }
  componentDidUpdate() {}

  componentDidMount() {
    const { query = {} } = this.props.location
    const { words = '' } = query

    if (!!words) {
      this.setState({
        kw: words,
        val: this.presetWords(words)
      }, () => {
        // setTimeout(() => {
        //   this.handleClick()
        // }, 500)
      })
    }
  }
  presetWords(kw) {
    return `8句关于${ kw }的文案，要求相似度不高，体现高级，添加修饰`
  }

  handleChange(e) {
    this.setState({
      val: e.target.value,
    })
  }
  handleClick() {
    trackEvent('gpt-cx', 'search')
    const { isEmoji, isFull, feel, len, kw, type, val, user_fake_id, session_id } = this.state
    const { isLogin, score = 0 } = this.props
    if (!isLogin) {
      Message.warn('登录中，请稍后')
      return
    }
    if (score < 15) {
      Message.warn('次数已用完，分享可获得更多生成机会')
      this.handlePopRule()
      return
    }
    const node = this.refs.answerNode
    node.textContent = ''
    if (!!!window.EventSource) {
      Message.warn('浏览器暂不支持，请使用新版 Chrome 或 Edge')
      return
    }
    this.setState({
      answerStatusText: '回答中...',
      postDraftStatus: false,
      loading: true,
    }, () => {
      this.timer = setTimeout(() => {
        this.setState({
          answerStatusText: '回答中...当前使用人数较多，请稍后...'
        })
      }, 15000)
    })

    const maskNode = this.refs.maskAnswerNode
    node.className = 'cursor'

    const query = {
      parent_id: 0,
      session_id,
      user_fake_id,
      kw,
      len,
      f: feel,
      em: type,
      emj: isEmoji ? 1 : 0,
      t: isFull ? 'l' : 's'
    }
    const userinfo = getStore('userinfo')

    if (userinfo && userinfo.uid) {
      query.uid = userinfo.uid
    }
    const eventSource = new EventSource(`${ baseApiUrl }/api/client/gpt/cx${ stringifyQuery(query) }`)
    eventSource.addEventListener('message', event => {
      // console.log(event, 'message')
      this.setState({
        answer: event.data.replace(/@_@/g, '\n'),
      }, () => {
        this.handlePopWord(node, maskNode)
      })
    })
    eventSource.addEventListener('custom-error', event => {
      Message.success(event.data)
      this.setState({
        loading: false,
        kw: '',
      })
      node.className = ''
      eventSource.close()
    })
    eventSource.addEventListener('close', (event) => {
      console.log('close')
      this.setState({ answerStatus: 3 }, () => {
        this.handlePopWord(node, maskNode)
      })
      eventSource.close()
    })

    eventSource.addEventListener('error', (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.log('EventSource connection closed');
      } else {
        console.error('EventSource error:', event);
      }
      const { answer } = this.state
      if (answer.length > 0) {
        this.setState({ answerStatus: 3 }, () => {
          this.handlePopWord(node, maskNode)
        })
      }

      eventSource.close()
    })
  }
  handlePopWord(node, maskNode) {
    const { answerStatus } = this.state
    const { onCheckScore } = this.props
    clearInterval(this.popTimer)

    this.popTimer = setInterval(() => {
      const text = maskNode.textContent || ''
      if (text.length > 0) {
        node.append(text.charAt(++this.answerIndex))

        if (this.answerIndex >= text.length - 1) {
          clearInterval(this.popTimer)
          if (this.answerIndex > text.length - 1) {
            this.answerIndex = text.length - 1
          }
          if (answerStatus === 3) {
            node.className = ''
            this.setState({
              loading: false
            }, onCheckScore)
            Message.success('回答结束')
          }
        }
      } else if (answerStatus === 3 && text.length === 0) {
        this.setState({
          loading: false,
          kw: '',
        })
        node.className = ''
        Message.success('请输入正确的关键字')
        clearInterval(this.popTimer)
      }
    }, 100)
  }
  handleAfterCopy() {
    const { postDraftStatus } = this.state
    trackEvent('gpt-cx', 'copy')
    Message.success('复制成功')
    if (postDraftStatus) return
    ///
    // console.log('发送草稿')

  }
  handleRetry() {
    trackEvent('gpt-cx', 'retry')
    this.answerIndex = -1
    this.setState({
      answer: '',
      answerStatus: 0,
    }, this.handleClick.bind(this))
  }
  handleSearch(val) {
    const { loading } = this.state
    if (loading) return
    if (!!!val) {
      Message.error('请输入关键字')
      return
    }
    this.answerIndex = -1
    this.setState({
      answer: '',
      answerStatus: 0,
      val: this.presetWords(val),
    }, this.handleClick.bind(this))
  }
  handleKeywordChange({ target }) {
    this.setState({
      kw: target.value.replace(/[，、；.。‘’“”（）(){}\[\]「」【】\\|]/g, ',').replace(/,+/g, ',')
    })
  }
  handleTypeChange(value) {
    this.setState({
      type: value
    })
  }
  handleFeelChange(value) {
    this.setState({
      feel: value
    })
  }
  handlePopRule() {
    const { onPopRule } = this.props
    onPopRule()
  }
  render() {
    const { feel, feelings, kw = '', answer = '', answerStatusText = '', answerStatus, loading } = this.state
    const { score = 0 } = this.props
    // <div className='gpt3-condition flex flex-justify-center flex-align-center flex-wrap'>
    //   <div className='gpt3-condition-item'>
    //     <span>情绪</span>
    //     <Select value={ feel } dropdownStyle={{ fontSize: '.12rem' }} placeholder='请选择类型' size='small' style={{ width: '.7rem' }} onChange={ this.handleFeelChange.bind(this) }>
    //       {
    //         feelings.map((o, i) => {
    //           return <Option key={ o } value={ i + 1 }>{ o }</Option>
    //         })
    //       }
    //     </Select>
    //   </div>
    // </div>
    return (
      <div className='gpt3'>

        <div className={`gpt3-prompt ${ loading ? 'disabled' : '' }`}>
          <img className='gpt3-prompt-logo' src={require('img/active/tab/cx.png')} />
          <TextArea
            placeholder={
`请输入需要重写的文案内容，300字以内`
            }
            maxLength={ 300 }
            rows={2}
            value={kw}
            loading={ loading }
            disabled={ loading }
            onChange={ this.handleKeywordChange.bind(this) }

            />
          <div className='gpt3-prompt-send' onClick={ this.handleSearch.bind(this) }>
            <img src={require('img/send.png')} />
          </div>
        </div>

        <div className={`answer-status ${ loading ? 'loading' : '' }`}>
          {
            loading ? answerStatusText : (
              <div className='flex flex-justify-center flex-align-center'>
                <span>
                  跪求分享 🙇🙇‍♂️ 🙇‍♀️ ，欢迎提
                  <Popover
                    placement="bottom"
                    content={
                      <div style={{ textAlign: 'center' }}>
                        <img alt='关注文案门' src={ require('img/qrcode.jpg') } width='200' />
                        <div style={{ marginTop: '.12rem' }}>微信搜索「<strong>文案门</strong>」</div>
                      </div>}
                    title="微信扫一扫"
                    trigger="hover"
                    >
                  <a href='#'>建议 & 反馈</a>
                  </Popover>
                </span>
              </div>
            )
          }
        </div>
        <div className='answer'>
          { (loading || answer.length > 0) ? '' : (
              <div>
                <div style={{ color: '#fff' }}>感受 AI 的魔法</div>
              </div>
            )
          }
          <pre ref='answerNode'></pre>
          <p ref='maskAnswerNode'><noscript>{ answer }</noscript></p>
          <Tooltip placement='left' title="点击查看获取方案">
            <div className='answer-count' onClick={ this.handlePopRule.bind(this) }>
              <span><strong>{ this.state.scoreCost }</strong>/次</span>
            </div>
          </Tooltip>

          <Popover
            placement="right"
            content={
              <div style={{ textAlign: 'center' }}>
                <img alt='扫码体验小程序' src={ require('img/qrcode_m.jpg') } width='200' />
                <div style={{ marginTop: '.12rem' }}>微信搜索「<strong>文案门</strong>」</div>
              </div>}
            title="微信扫一扫，体验小程序"
            trigger="hover"
            >
            <div className='mp-guide' >
              <img src={ require('img/active/mp_g.png') } />
            </div>

          </Popover>
        </div>
        {
          answerStatus === 3 && answer.length > 0 && !loading && (
            <div className='gpt3-btn-group flex flex-justify-between flex-align-center'>
              <div></div>
              <Clipboard value={ answer.replace('\n\n', '\n').split('\n').join('\n\n').concat('\n\n #文案门') }
                         completeCopy={ this.handleAfterCopy.bind(this) }>
                  <Button shape="round" type='primary' icon='copy'>复制答案</Button>
              </Clipboard>
            </div>
          )
        }
      </div>
    )
  }
}

export default GPT3

// <Button style={{ marginLeft: '.12rem' }} type='link' icon='reload'
//         onClick={ this.handleRetry.bind(this) }>重新生成</Button>
