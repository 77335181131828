import React from 'react'
import { Input, Button } from 'antd'
import Creator from '../creator'
import MyIcon from 'component/my-icon'
import GAds from 'lib/component/gads'
import { setTitle } from 'lib'
import { localImageToBase64 } from '../creator/utils/img'
import './index.less'

const { TextArea } = Input

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pic: '',
      _id: '2ad666ce66c18c02077952216385063f'
    }
    this.fileInputRef = React.createRef();
    setTitle('自定义 MEMES|迷因|梗图 制作')
  }
  componentDidMount() {
   
  }
  openFileDialog = () => {
    if (this.fileInputRef.current) this.fileInputRef.current.click();
  }
  handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      localImageToBase64(file, base64 => {
        this.setState({
          pic: base64
        })
      })
    }
    event.target.value = ''
  }
  handleClear() {
    this.setState({
      pic: ''
    })
  }
  render() {
    const { _id, pic } = this.state
    return (
      <div className='meme-free'>
        <div className='meme-free-info'>
          <div className='meme-free-info-name'>自定义 MEME</div>
          <div className='flex flex-justify-start flex-align-center'>
            {
              !!pic ? (
                <Button type='dashed' icon='close' onClick={this.handleClear.bind(this)}>更换图片</Button>
              ) : (
                <Button type='ghost' icon='upload' onClick={this.openFileDialog.bind(this)}>
                  上传图片
                  <input
                    type="file"
                    accept="image/*"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
                </Button>
              )
            }
            <div className='meme-free-info-tips'>注：双击按钮传图，PC端体验更加</div>
          </div>
        </div>
        
        <div>
          {
            pic && <Creator id={ _id } pic={ pic } model='new' />
          }
        </div>
        <GAds slot='2051844730' />
      </div>
    )
  }
}
export default Home
