import React from 'react'
import { message, Icon, Popover, Button, Tooltip } from 'antd'
import Clipboard from 'lib/component/copyToClipbord'
import WenanActions from '../wenan-actions'
import { baseApiUrl } from 'config'
import { trackEvent } from 'lib'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isMatchKeyword: false
  }
  componentWillMount() {
    const { keywords = [] } = this.props
    if (keywords.length > 0) {
      this.setState({
        isMatchKeyword: true
      })
    }
  }
  handleMatchKeywords(words) {
    const { keywords } = this.props

    let matched = words.match(new RegExp(keywords.join("|"), "g"))
    matched = [...new Set(matched)]
    // console.log(matched)
    matched.map(o => {
      const rep = `<span class='wenan-item-word-light'>${ o }</span>`
      words = words.replace(new RegExp(o, "g"), rep)
    })

    return words
    // console.log(str)

  }
  render() {
    const { item, showArticle } = this.props
    const { isMatchKeyword } = this.state
    return (
      <div className='wenan-item'>
        {
          isMatchKeyword ? (
            <div className='wenan-item-word' dangerouslySetInnerHTML={{ __html: this.handleMatchKeywords(item.word) }}></div>
          ) : (
            <div className='wenan-item-word'>{ item.word }</div>
          )
        }
        {
          showArticle && item.articleTitle && (
            <div className='wenan-item-article'>
              {
                isMatchKeyword ? (
                  <a className='wenan-item-article-title' href={ `http://www.wenanmen.com/pages/s/0ab5303b628a241c0535d12a534efae6.html${ item.articleId }` }
                    dangerouslySetInnerHTML={{ __html: `出自「${ this.handleMatchKeywords(item.articleTitle) }」` }}></a>
                ) : (
                  <a className='wenan-item-article-title'>
                    出自「{ item.articleTitle }」
                  </a>
                )
              }

            </div>
          )
        }
        <div className='wenan-item-action'>
          <div className='wenan-item-action-item'>
            <Icon type="fire" /><span>{ item.hot }</span>
          </div>
          <WenanActions wenan={ item } visible={ true } source='new'  />
        </div>
      </div>
    )
  }
}
