import React from 'react'
import { Tag, Tooltip, message, Modal, Input, Button, Icon, Spin, Switch } from 'antd'
import { get, moment, send, getStore, setStore } from 'mulan-lib'
import BlockTitle from 'lib/component/block-title'
import Clipboard from 'lib/component/copyToClipbord'
import Tool from './tool'
import ToolItem from './tool-item'
import html2Canvas from 'html2canvas'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'
import unfetch from 'isomorphic-unfetch'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showPic: false,
      hideSy: false,
      showSyPop: false,
      originHideKey: '',
      template: 'half-rect',
      templateList: [
        { name: '全屏-竖', key: 'full-rect', pic: true },
        { name: '全屏-方', key: 'full-square', pic: true },
        { name: '半屏-竖', key: 'half-rect', pic: true },
        { name: '半屏-方', key: 'half-square', pic: true },
      ],
      color: 'white',
      colorList: [
        { name: '白', key: 'white', },
        { name: '黑', key: 'black', },
      ],
      fontStyle: 'font-style-normal',
      fontStyleList: [
        { name:'正常', key: 'font-style-normal' },
        { name:'斜体', key: 'font-style-italic', style: { fontStyle: 'italic' } }
      ],
      tags: [] //["机会", "一生"]
    }
    this.saveDom = null
    setTitle(`文案海报`)
  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
  }
  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({ tags });
  }
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    // console.log(tags);
    this.setState({
      tags,
      total: 0,
      inputVisible: false,
      inputValue: '',
    });
  }

  saveInputRef = input => (this.input = input);

  handleChangeTemplate(template) {
    this.setState({
      template
    })
  }
  handleChangeColor(color) {
    this.setState({
      color
    })
  }
  handleChangeFontStyle(fontStyle) {
    this.setState({
      fontStyle
    })
  }
  handleSwitchSy(checked) {
    const { originHideKey } = this.state
    if (!!!originHideKey) {
      this.setState({
        hideSy: false
      })
      return
    }
    const hidekey = getStore('hidekey')

    if (hidekey === originHideKey) {
      this.setState({
        hideSy: checked
      })
    } else {
      this.setState({
        hideSy: false,
        showSyPop: true
      })
    }

  }
  handleReset() {
    this.setState({
      loading: true,
    })
  }
  componentWillMount() {
    const { params } = this.props
    const { id } = params

    const wenan = getStore('wenan', 'session')

    // message.warn('服务升级中，日后会正常开放，感谢！', 30)
    // return

    if (wenan) {
      const { word } = wenan
      setTitle(`海报：${ word }`)
      this.setState({
        wenan,
        word,
        wenanId: id,
      }, this.handleGetKeyword.bind(this))
    } else {

      this.setState({
        // word,
        wenanId: id,
      }, this.handleGetWenan.bind(this))
    }
    this.handleGetHideKey()
  }

  handleGetWenan() {
    const { wenanId } = this.state

    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan',
        data: {
          id: wenanId,
        }
      }),
      data => {
        // console.log(data)
        const { word } = data
        setTitle(`海报：${ word }`)

        this.setState({
          word,
          wenan: data,
        }, this.handleGetKeyword.bind(this))
      }
    )
  }
  handleGetHideKey() {
    processRequest(
      get('/api/admin/common/hidekey'),
      data => {
        console.log(data)
        this.setState({
          originHideKey: data
        })
      }
    )
  }
  handleGetKeyword() {
    const { word } = this.state

    processRequest(
      get('/api/admin/common/kw', { word }),
      data => {
        this.setState({
          tags: data
        }, this.handleGetPhoto.bind(this))
      }
    )
  }
  handleGetPhoto() {
    trackEvent('poster', 'next')
    const { total, tags } = this.state
    this.setState({
      showPic: false,
      loading: true
    })
    processRequest(
      get('/api/admin/common/photo', {
        kw: tags.join(','),
        page: total === 0 ? 0 : ~~(Math.random() * total)
      }),
      data => {
        // console.log(data)
        const { src, total, author } = data
        if (!!src && total > 0) {
          this.setState({
            src,
            total,
            author,
          }, this.handlePreloadPhoto.bind(this, src))
        } else {
          message.warn('未找到匹配海报，请重试！')
          this.setState({
            loading: false
          })
        }

      }
    )
  }
  handlePopPic(pic) {
    Modal.confirm({
      title: '',
      className: 'pic-modal',
      okText: '下载图片',
      cancelText: '关闭',
      maskClosable: true,
      onOk: () => {
        this.saveImg(pic)
      },
      content: (
        <div>
          <img src={ pic } style={{ width: '100%', boxShadow: '0 0 5px 3px rgba(0,0,0,.05)' }} />
          <div style={{ color: '#ccc', fontSize: '.12rem', textAlign: 'center', marginTop: '.06rem' }}>点击「下载图片」，手机端请长按图片保存</div>
        </div>
      ),
      icon: false,
      footer: false
    })
    // <div style={{ color: '#ccc', fontSize: '.12rem', textAlign: 'center', marginTop: '.06rem' }}>点击「下载图片」保存</div>
  }
  imageToBlob(base64) {
    const byteCharacters = window.atob(
      base64.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: undefined,
    });
    return blob
  }
  saveImg(base64) {
    trackEvent('poster', 'save')
    const blob = this.imageToBlob(base64)
    const file_name = `wenanmen_poster_${ Date.now() }.jpg`
    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name)
    }
    // for Non-IE (chrome, firefox etc.)
    else {
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      var url = window.URL.createObjectURL(blob)
      a.href =  url
      a.target = '_blank'
      a.download = file_name
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url)
    }
  }
  handleGeneratePhoto() {
    trackEvent('poster', 'generate')
    this.setState({
      loading: true,
    })
    this.saveDom = this.refs.im_node
    html2Canvas(this.saveDom, {
      useCORS: true,
      scale: 4,
      background:'transparent',
      dpi: window.devicePixelRatio,
    }).then(canvas => {
      const base64Pic = canvas.toDataURL("image/jpeg", 1)
      const base64PicCompress = canvas.toDataURL("image/jpeg", .3)
      this.handlePopPic(base64Pic)
      this.setState({
        loading: false,
      }, () => this.handlePushEvent({
        base64Pic,
        base64PicCompress,
      }))
      message.success('海报已生成')
    })
  }
  handlePushEvent({
    base64Pic, base64PicCompress
  }) {
    const { wenan, wenanId } = this.state
    const userinfo = getStore('userinfo')
    if (!!!wenanId || !!!userinfo) return

    this.handleUploadPoster(base64Pic, uploadedPic => {
      this.handleUploadPoster(base64PicCompress, uploadedPic2 => {
        const { uid } = userinfo
        processRequest(
          send('/api/admin/common/func', {
            url: 'event/create',
            data: {
              id: wenanId,
              wenan,
              userinfo,
              type: 'picture',
              uid,
              pic: uploadedPic,
              thumbnail: uploadedPic2,
              wenanId,
            }
          }),
          data => {
            console.log(data)
          }
        )

      })
    })

  }
  handleUploadPoster(base64Pic, cb) {
    // return

    if (!!!window.File || !!!window.FormData) return
    processRequest(
      get('/api/admin/common/wxup'),
      data => {
        // console.log(data)
        const { file_name, url, path, authorization, token, cos_file_id, file_id } = data
        const blob = this.imageToBlob(base64Pic)
        // const file_name = `wenanmen_poster_${ Date.now() }.jpg`
        const file = new File([blob], file_name, { type: 'image/jpeg' });
        const formData = new FormData()
        formData.append('key', path)
        formData.append('Signature', authorization)
        formData.append('x-cos-security-token', token)
        formData.append('x-cos-meta-fileid', cos_file_id)
        formData.append('file', file)

        unfetch(url, {
          method :"POST",
          body: formData,
        }).then(res => {
          if (res.ok) {
            cb(file_id)
          }
        })
      }
    )

  }
  handlePreloadPhoto(src) {
    const img = new Image()
    img.src = src
    img.onload = () => {
      // console.log(1)
      this.setState({
        showPic: true,
        loading: false
      })
    }
  }
  handleCheckSyKey() {
    const { sykey, originHideKey } = this.state
    if (sykey === originHideKey) {
      this.setState({
        hideSy: true,
        showSyPop: false,

      }, () => {
        setStore('hidekey', sykey)
      })
      message.success('验证成功')
    } else {
      message.warn('请输入正确的密钥')
    }
  }
  handleAfterCopy() {
    trackEvent('poster', 'copy')
    message.success("复制成功", 2)
  }
  render() {
    const {
      loading = false,
      src = '',
      wenanId = '',
      showPic = false,
      word = '',
      template = 'half-rect',
      templateList = [],
      color = 'white',
      colorList = [],
      fontStyle = 'font-style-normal',
      fontStyleList = [],
      tags = [],
      inputVisible, inputValue,
    } = this.state

    return (
      <div className={ `poster ${ loading  ? 'loading' : '' }` }>
        <h1 className='child-page-title'>文案海报</h1>
        <div className='poster-container'>
          <div ref='im_node' className={ `poster-container-view ${ template } ${ color } ${ fontStyle } ` }>
            <div className='poster-container-view-photo' >
              <div className='poster-container-view-photo-bg' style={{
                // backgroundImage: `url(${ src })`,
                opacity: showPic ? 1 : 0
              }}><div className='flex flex-justify-center flex-align-center wp100 hp100'><img src={ src } /></div></div>
            </div>
            <div className='poster-container-view-word'>
              { word.length < 80 ? word : `${ word.substr(0, 80) }...`  }
              {
                !this.state.hideSy && (
                  <img src={ require('img/logo_word2.png') } className='poster-container-view-word-sy' />
                )
              }
            </div>
          </div>
        </div>
        <div className='poster-tool-tips tongzhi'>图片服务异常，功能将无法正常使用，请明天再试。</div>
        <div className='poster-tool-tips'>注：手机端请长按图片进行保存</div>
        <div className='poster-tool-keywords'>
          {
            tags && (
              <div className='flex flex-justify-start flex-align-center flex-wrap'>
                <div className='poster-tool-keywords-title'>关键字：</div>
                <div className='tag-container'>
                  <div className='tag-container-content' >
                    {
                      tags.map((tag, index) => {
                        const isLongTag = tag.length > 20
                        const tagElem = (
                          <Tag key={tag} closable={ true } onClose={() => this.handleClose(tag)}>
                            { isLongTag ? `${ tag.slice(0, 4) }...` : tag }
                          </Tag>
                        )
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        )
                      })
                    }
                    {
                      inputVisible && (
                        <Input
                          ref={this.saveInputRef}
                          type="text"
                          size="small"
                          style={{ width: 80, backgroundColor: 'rgba(255,255,255,.6)' }}
                          value={inputValue}
                          maxLength={ 6 }
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputConfirm}
                          onPressEnter={this.handleInputConfirm}
                        />
                      )
                    }
                    {
                      !inputVisible && tags.length < 5 && (
                        <Tag onClick={this.showInput} style={{ background: 'rgba(255,255,255,.1)', borderStyle: 'dashed', cursor: 'pointer' }}>
                          <Icon type="plus" /> 关键字
                        </Tag>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className='poster-tool-action'>
          <Button loading={ loading } type='ghost' onClick={ this.handleGetPhoto.bind(this) } icon='reload' >换一张</Button>
          <Button loading={ loading } type='primary' icon='smile' onClick={ this.handleGeneratePhoto.bind(this) }>生成</Button>
          <Clipboard value={ `${ word } \n\n #文案门` }
                     completeCopy={ this.handleAfterCopy.bind(this) }>
            <Button loading={ loading } type='ghost' icon='copy'>复制文案</Button>
          </Clipboard>
        </div>

        <div className='poster-tool-container'>
          <Tool name='海报样式：'>
            {
              templateList.map(({ name, key, pic }) => {
                return (
                  <ToolItem
                    currentKey={ template }
                    value={ key }
                    pic={pic}
                    onClick={ this.handleChangeTemplate.bind(this, key) } >{ name }</ToolItem>
                )
              })
            }
          </Tool>
          <Tool name='文字背景：'>
            {
              colorList.map(({ name, key }) => {
                return (
                  <ToolItem
                    currentKey={ color }
                    value={ key }
                    onClick={ this.handleChangeColor.bind(this, key) } >{ name }</ToolItem>
                )
              })
            }
          </Tool>
          <Tool name='文字样式：'>
            {
              fontStyleList.map(({ name, key, style }) => {
                return (
                  <ToolItem
                    currentKey={ fontStyle }
                    value={ key }
                    style={ style }
                    onClick={ this.handleChangeFontStyle.bind(this, key) } >{ name }</ToolItem>
                )
              })
            }
          </Tool>
          <Tool name='去除水印：'>
            <Switch checked={ this.state.hideSy } onChange={ this.handleSwitchSy.bind(this) }  />
          </Tool>
        </div>


        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
        <Modal
          visible={ this.state.showSyPop }
          okText='确定'
          cancelText='关闭'
          onCancel={ () => this.setState({ showSyPop: false }) }
          onOk={ this.handleCheckSyKey.bind(this) }
          >
          <div className='flex flex-justify-center '>
            <img src={ require('img/qrcode.jpg') } style={{ width: '2.5rem', boxShadow: '0 0 5px 3px rgba(0,0,0,.1)', borderRadius: '.05rem' }} />
          </div>
          <div style={{
              fontSize: '.14rem',
              color: '#333',
              textAlign: 'center',
              margin: '.12rem 0 .24rem 0'
          }}>关注「文案门」公众号，回复 “去水印” 获取密钥</div>

        <div><Input placeholder='请输入密钥，验证成功可享一周免水印' value={ this.state.sykey } onChange={ ({ target }) => this.setState({ sykey: target.value }) } /></div>
        </Modal>
      </div>
    )
  }
}

export default Home

// <div className='poster-container-guide'>
//   {
//     wenanId && (
//       <div>
//         <div className='poster-container-guide-tips'>在手机中生成</div>
//         <div className='poster-container-guide-qrcode'>
//           <img src={ `${ baseApiUrl }/api/admin/common/qrcode?page=pages/pexels/index&scene=${ wenanId }` } />
//         </div>
//
//       </div>
//     )
//   }
// </div>
