const imageToBlob = base64 => {
    const byteCharacters = window.atob(
        base64.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
        type: undefined,
    });
    return blob
}

export const saveImg = base64 => {
    const blob = imageToBlob(base64)
    const file_name = `wenanmen_com_memes_${Date.now()}.jpg`
    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, file_name)
    }
    // for Non-IE (chrome, firefox etc.)
    else {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.target = '_blank'
        a.download = file_name
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url)
    } 
}
export const localImageToBase64 = (file, callback) => {
    if (file.type.startsWith('image/')) {
        const reader = new FileReader()

        reader.onload = e => {
            const arrayBuffer = e.target.result
            const urlReader = new FileReader()
            urlReader.onload = e2 => {
                const imgSrc = e2.target.result
                callback(imgSrc)
            }
            urlReader.readAsDataURL(file)
        }
        reader.readAsArrayBuffer(file)
    }
}