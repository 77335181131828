import React from 'react'
import { Spin, Tabs, Button, Input } from 'antd'
import { send, getStore } from 'mulan-lib'
import WenanCardItem2 from 'lib/component/wenan-card-item2'
import WenanCardItem from 'lib/component/wenan-card-item2'
import LoadMore from 'lib/component/load-more'
import BlockTitle from 'lib/component/block-title'
import UserAvatar from 'lib/component/user-avatar'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

const { TabPane } = Tabs
const { TextArea } = Input

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      // currentTab: '1'  ,
      loading: true,
      type: 'copy',
      from: 0,
      max: 3,
      list: [],
      isSelf: false,
      hasMore: true
    }

  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
    const { params } = this.props
    const { uid: fromUid } = params
    const uid = getStore('uid')
    if (fromUid) {
      this.handleGetUserInfo(fromUid)
    }
    if (uid === fromUid) {
      this.setState({
        isSelf: true
      })
    }
  }
  handleTransPic(picList, cb) {
    const fileList = picList.reduce((list, curr) => {
      const { pic, thumbnail } = curr
      list.push(pic)
      list.push(thumbnail)
      return list
    }, [])
    //
    processRequest(
      send('/api/admin/common/func', {
        url: 'event/trans/pic',
        data: {
          fileList,
        }
      }),
      data => {
        cb(data || [])
      }
    )
  }
  handleGetList() {
    // this.handleReset()
    const { userinfo } = this.state
    const { uid } = userinfo
    if (!!!uid) return

    processRequest(
      send('/api/admin/common/func', {
        url: 'event/list',
        data: {
          type: this.state.type,
          uid,
          from: this.state.from,
          max: this.state.max,
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        const picList = data.filter(o => (o.type === 'picture'))

        if (picList.length === 0) {
          this.setState({
            loading: false,
            list: list.concat(data || []),
          })
        } else {
          this.handleTransPic(picList, reply => {
            // console.log(reply)
            const picList = reply.reverse()
            this.setState({
              loading: false,
              list: list.concat(data.map(o => {
                if(o.type === 'picture') {
                  o.pic = picList.pop()
                  o.thumbnail = picList.pop()
                }
                return o
              })),
            })
          })
        }

      }
    )
  }
  handleInfiniteOnLoad = () => {
    trackEvent('center', 'next')
    let { list, from, max, updateCount } = this.state
    this.setState({
      loading: true,
      from: from + max
    }, this.handleGetList.bind(this))
  }
  handleGetUserInfo(uid) {
    // userinfo
    processRequest(
      send('/api/admin/common/func', {
        url: 'userinfo',
        data: {
          uid,
        }
      }),
      data => {
        const { userinfo } = data
        const { nickname = '' } = userinfo
        setTitle(`${ nickname }的文案空间`)
        this.setState({
          userinfo,
        }, this.handleGetList.bind(this))
      }
    )
  }
  handleTabChange(key) {
    this.setState({
      type: key,
      loading: true,
      list: [],
      from: 0,
    }, this.handleGetList.bind(this))
  }
  render() {
    const { loading = true, type, list = [], hasMore, userinfo = {}, isSelf } = this.state
    const { nickname = '', headimgurl = '' } = userinfo
    return (
      <div className={ `user-center ${ loading  ? 'loading' : '' }` }>
        <div className='user-center-head'>
          <div className='user-center-head-avatar'>
            {
              (nickname || headimgurl) && (
                <UserAvatar showSource={ false } nickname={ nickname } avatar={ headimgurl } size='large' />
              )
            }
          </div>
          <div className='user-center-head-nickname'>{ nickname }</div>
          <div dangerouslySetInnerHTML={{ __html: `<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g class="parallax">
<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
</g>
</svg>
` }} />
        </div>
        <div className='user-center-body'>
          <Tabs defaultActiveKey="1" activeKey={ this.state.type } onChange={ this.handleTabChange.bind(this) }>
            <TabPane tab="近期动态" key="copy">
              <div className='user-center-body-tab'>
                {
                  !loading && list.length === 0 && (
                    <div className='user-center-body-tab-empty'>暂无数据</div>
                  )
                }
                {
                  (!loading || (list.length > 0 && type === 'copy' )) && (
                    list.map(o => {
                      return (<WenanCardItem obj={ o } isHideUserInfo />)
                    }).concat([
                      <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } />
                    ])
                  )
                }
              </div>
            </TabPane>
            <TabPane tab={ `${ !!isSelf ? '我' : '他'  }的收藏` } key="favorite">
              <div className='user-center-body-tab'>
                {
                  !loading && list.length === 0 && (
                    <div className='user-center-body-tab-empty'>暂无数据</div>
                  )
                }
                {
                  (!loading || (list.length > 0 && type === 'favorite' )) && (
                    list.map(o => {
                      return (<WenanCardItem obj={ o } isHideUserInfo />)
                    }).concat([
                      <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } />
                    ])
                  )
                }
              </div>
            </TabPane>
            <TabPane tab={ `${ !!isSelf ? '我' : '他'  }的创作` } key="creator">
              <div className='user-center-body-tab'>
                {
                  !!isSelf ? (
                    <div className='user-center-body-tab-creator'>
                      <TextArea placeholder='请输入文案' rows={4} maxLength={400} />
                      <div className='flex flex-justify-between flex-align-center'>
                        <Input placeholder='请输入作者'/>
                        <Button type='primary' disabled>暂未开放</Button>
                      </div>

                    </div>
                  ) : ''
                }
                {
                  !loading && list.length === 0 && (
                    <div className='user-center-body-tab-empty'>暂无数据</div>
                  )
                }
                {
                  (!loading || (list.length > 0 && type === 'favorite' )) && (
                    list.map(o => {
                      return (<WenanCardItem obj={ o } isHideUserInfo />)
                    }).concat([
                      <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } />
                    ])
                  )
                }
              </div>
            </TabPane>
          </Tabs>
          {this.state.loading && this.state.hasMore && (
            <div className="loading-container">
              <Spin />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Home

// <div className='article-list-item'>
//   <a href={`//www.wenanmen.com/pages/s/${ item._id }.html`} target='_blank' style={{ color: 'initial' }}>
//     <div className='article-list-item-title'>
//       { item.title }
//     </div>
//     <div className='article-list-item-sub-title'>
//       { item.digest }
//     </div>
//   </a>
//   <div className='article-list-item-create-time'>{ moment('MM/DD')(item.createdTime) }</div>
// </div>
