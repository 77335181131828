import React from 'react'
import { Modal, Carousel, List, Spin, Avatar, message, Icon, Popover } from 'antd'
import { browserHistory } from 'react-router'
import { send } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 12,
      list: [],
      hasMore: true
    }
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 12,
      list: []
    })
  }
  componentDidMount() {
  }
  handleGetList() {
    // this.handleReset()
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/list',
        data: {
          from: 0,
          max: 3,
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        this.setState({
          loading: false,
          list: list.concat(data || []),
        })
      }
    )
  }
  handlePopQrcode() {
    trackEvent('ad', '2023')
    Modal.info({
        title: '微信扫一扫，送亲友兔年祝福！',
        content: (
          <div style={{ marginTop: '.5rem' }}>
            <img src={ require('img/common/m_qrcode.jpeg') } style={{ width: '2rem' }} />
          </div>
        ),
        onOk() {},
      });
  }
  render() {
    const { loading = true, pexels = { src: '' } } = this.state


    // <div className='carousel-item' onClick={ this.handlePopQrcode.bind(this)  }>
    //   <img src={ require('img/common/2023.png') } alt='文案门新春送祝福' />
    // </div>
    return (
      <div className={ `extension` }>
        <a href='https://www.wenanmen.com/pages/download.html' target='_blank' className='flex flex-justify-between flex-align-center'>

          <div className='extension-title'>
            文案门·小插件 <span>v4.5.3</span>
          </div>

          <div className='extension-tips'>
            <div>文案、美图、笔记，灵感一触即发</div>
            <Icon type="swap-right" />
          </div>

        </a>
      </div>
    )
  }
}

export default Home
