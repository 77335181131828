import React from 'react'
import { Icon } from 'antd'
import { browserHistory } from 'react-router'
import './index.less'

class Emoji extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {}
  render() {
    return (
      <div className={ `extension meme-extension`  }>
        <a href='https://www.wenanmen.com/memes' target='_blank' className='flex flex-justify-between flex-align-center'>

          <div className='extension-title'>
            迷因 | 梗图 | <strong>memes</strong>
          </div>

          <div className='extension-tips'>
            <div>来制作</div>
            <Icon type="swap-right" />
          </div>

        </a>
      </div>
    )
  }
}

export default Emoji
