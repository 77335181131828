import React from 'react'
import { getStore, get, send, stringifyQuery } from 'mulan-lib'
import { Tabs, Message, Icon, Tooltip } from 'antd'
import GAds from 'lib/component/gads'
import Mask from 'lib/component/mask'
import ActiveBox from './active-box'
import Normal from './normal'
import Xhs from './xhs'
import Cx from './cx'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'

import './index.less'

const { TabPane } = Tabs
class GPT3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      score: 0,
      isLogin: false,
      currentKey: 'wa'
    }
    setTitle('文案 GPT - AI帮你写文案')
  }
  componentDidUpdate() {}

  componentDidMount() {
    window.document.body.scrollTo(0,0)
    const { params = {} } = this.props
    const { type } = params
    if (!!type) {
      this.setState({
        currentKey: type
      })
    }

    this.handleCheckScore()
  }
  handleCheckScore() {
    const uid = getStore('uid')

    if (!!uid) {
      this.setState({
        isLogin: true,
      }, () => {
        processRequest(
          send('/api/admin/common/func', {
            url: 'score',
            data: {
              uid,
            }
          }),
          data => {
            this.setState({
              score: data
            })
          }
        )
      })

    } else {
      console.log('登录中，500ms后重试')
      setTimeout(() => {
        this.handleCheckScore()
      }, 500)
    }
  }
  handlePopRule() {
    Mask(<ActiveBox onFetchScore={ this.handleCheckScore.bind(this) } />)
  }
  handleTabClick(key, tab) {
    this.setState({
      currentKey: key,
    })
  }
  render() {
    const { loading, score = 0, currentKey, isLogin } = this.state
    //
    return (
      <div className='ai'>
        <h1 className='child-page-title' style={{ position: 'relative' }}>
          文案 GPT
          <div className='ai-score'>
            <Tooltip placement='left' title="每日+15，点击查看获取方案">
              <div className='ai-score-count' onClick={ this.handlePopRule.bind(this) }>
                <img src={ require('img/active/score-g.png') } className='ai-score-count-icon' />
                <span>{ score }</span>
              </div>
            </Tooltip>
          </div>
        </h1>
        <Tabs className={ currentKey } activeKey={ currentKey } onTabClick={ this.handleTabClick.bind(this) }>
          {
            [
              {
                name: '通用', icon: 'wa',
                component: <Normal {...this.props}
                                   score={ score }
                                   isLogin={ isLogin }
                                   onPopRule={ this.handlePopRule.bind(this) }
                                   onCheckScore={ this.handleCheckScore.bind(this) }
                                   />
              },
              {
                name: '小红书', icon: 'xhs',
                component: <Xhs {...this.props}
                                   score={ score }
                                   isLogin={ isLogin }
                                   onPopRule={ this.handlePopRule.bind(this) }
                                   onCheckScore={ this.handleCheckScore.bind(this) }
                                   />
              },
              {
                name: '重写', icon: 'cx',
                component: <Cx {...this.props}
                                   score={ score }
                                   isLogin={ isLogin }
                                   onPopRule={ this.handlePopRule.bind(this) }
                                   onCheckScore={ this.handleCheckScore.bind(this) }
                                   />
              },
            ].map((o, i) => {
              const { name, icon, component, borderColor } = o
              return (
                <TabPane
                  key={ name }
                  tab={
                    <span className='ai-tab-title'>
                      <img style={ !!borderColor ? {borderColor} : {border:'none'} } src={ require(`img/active/tab/${ icon }.png`) } />
                      {name}
                    </span>
                  }
                  key={ icon }
                >
                  { component || `即将上线` }
                </TabPane>
              )
            })
          }
        </Tabs>
        <GAds slot='1587611196' />
      </div>
    )
  }
}

export default GPT3

// <Button style={{ marginLeft: '.12rem' }} type='link' icon='reload'
//         onClick={ this.handleRetry.bind(this) }>重新生成</Button>
