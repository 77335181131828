import React from 'react'
import { Tooltip } from 'antd'
import { getStore } from 'mulan-lib'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isLocal: true,
  }
  componentDidMount() {
    this.preset()
  }
  preset() {
    const { avatar, nickname } = this.props
    // console.log(avatar, nickname)
    if (!!!avatar && nickname) {
      this.setState({
        locName: nickname.replace('的', '').split('')[0],
        locColor: this.randomColor()
      })
    } else {
      this.setState({
        locName: '匿名用户',
        locColor: this.randomColor()
      })
    }
  }
  randomColor() {
    const colors = ['E80F88', 'AF0171', '790252', '4C0033', 'E94560', '533483',
                    '0F3460', '16213E', '553939', '395B64', 'D49B54', 'C84B31',
                    'A13333', '1E5128', '3E2C41', '2C394B', '7D0633', 'E14594']
    return colors[~~(Math.random() * colors.length)]
  }

  render() {
    const { avatar = '', size = 'normal', tips = '', onClick= () => {}, showSource = true } = this.props
    const { locName = '', locColor = 'fff' } = this.state
    return (
      <div className={ `user-avatar ${ size }` } onClick={ onClick } style={{ backgroundColor: `${ locColor ? `#${ locColor }` : '#fff' }`}}>
        <Tooltip placement='bottom' title={ tips }>
          <div className='user-avatar-content'>
            {
              !!!avatar ? (
                <span>{ locName }</span>
              ) : (
                <img src={ avatar } />
              )
            }
          </div>
          {
            !!showSource && (
              <img className='user-avatar-source' src={ require(`img/common/${ !!avatar ? 'xcx' : 'pc' }.png`) } />
            )
          }
        </Tooltip>
      </div>

    )
  }
}
