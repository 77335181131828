import React from 'react'
import { Icon, Input, Button, message } from 'antd'
import { browserHistory } from 'react-router'
import Clipboard from 'lib/component/copyToClipbord'
import Mask from 'lib/component/mask'
import './index.less'

const { TextArea } = Input


class Emoji extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      rText: '',
      tText: '',
    }
  }
  componentDidMount() {
    // Mask(<LoginForm />)
  }
  handleChangeText({ target }) {
    this.setState({
      text: target.value
    })
  }
  handleChangeRText({ target }) {
    this.setState({
      rText: target.value
    })
  }
  handleClick() {
    const { text, rText } = this.state
    const regx = new RegExp(`${ rText }`, 'g')
    const result = text.replace(regx, '').split('\n').map((o, i) => {
      return `${ i + 1 }、${ o }`
    }).join('\n\n')

    this.setState({
      tText: result
    })
  }
  render() {
    const { text, rText, tText } = this.state
    return (
      <div className={ `tools` }>
        <TextArea value={ text } rows={ 5 } onChange={ this.handleChangeText.bind(this) } />
        <Input value={ rText } onChange={ this.handleChangeRText.bind(this) } />
        <TextArea value={ tText } rows={ 5 } onChange={ this.handleChangeText.bind(this) } />

        <Button onClick={ this.handleClick.bind(this) }>转换一下</Button>
        <br /><br />
        <Clipboard value={ `${ tText }` }
                   completeCopy={ () => message.success('复制成功') }><Button>复制</Button></Clipboard>
      </div>
    )
  }
}

export default Emoji
