import { fabric } from 'fabric'
const clientWidth = window.document.documentElement.clientWidth
const fixMobile = n => clientWidth < 1000 ? n * 2 : n
fabric.Object.prototype.set({
	borderColor: 'white',
	cornerColor: 'white', //激活状态角落图标的填充颜色
	cornerStrokeColor: '#000000', //激活状态角落图标的边框颜色
	borderOpacityWhenMoving: 1,
	borderScaleFactor: 2,
	cornerSize: fixMobile(10), //操作点的像素
	cornerStyle: 'circle', //rect,circle,操作点是圆点还是小方块
	centeredScaling: false, //角落放大缩小是否是以图形中心为放大原点
	centeredRotation: true, //旋转按钮旋转是否是左上角为圆心旋转
	transparentCorners: false, //激活状态角落的图标是否透明
	rotatingPointOffset: 0, //旋转距旋转体的距离
	originX: 'left',
	originY: 'top',
	// scaleX: 2,//不起作用
	// scaleY: 2,
	lockScalingFlip: true,
	lockUniScaling: true, //当设置为true，Object将无法被锁定比例进行缩放。默认值为false
	hasRotatingPoint: true, //是否显示旋转按钮
	selectionDashArray: [5, 5],
	// minScaleLimit: 0.5,//对整个画布生效
})
fabric.Object.prototype.setControlsVisibility({
	ml: false, //中左，中下，中右，中上、旋转五个点隐藏
	mb: false,
	mr: false,
	mt: false,
	// mtr: false,
})
// function renderIcon(image, initialAngle) {
//   return function (ctx, left, top, styleOverride, fabricObject) {
//       let size = this.cornerSize;
//       ctx.save();
//       ctx.translate(left, top);
//       // ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle + initialAngle));
//       ctx.drawImage(image, -size / 2, -size / 2, size, size);
//       ctx.restore();
//   };
// }

// fabric.Image.fromURL(require('img/icon/rotate.png'), (image, isError) => {
//   if (!isError) {
//       fabric.Object.prototype.controls.mlr = new fabric.Control({
//           x: 0,
//           y: -0.5,
//           offsetY: -20,
//           cursorStyle: 'pointer',
//           actionHandler: fabric.controlsUtils.rotationWithSnapping,
//           cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
//           // 渲染图标
//           render: renderIcon(image._element, 0),
//           // 设置控制点大小
//           cornerSize: 20
//       })
//   }
// })
var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
fabric.util.object.extend(fabric.Canvas.prototype, {
	_onTouchStart: function (e) {
		var target = this.findTarget(e);
		// if allowTouchScrolling is enabled, no object was at the
		// the touch position and we're not in drawing mode, then
		// let the event skip the fabricjs canvas and do default
		// behavior
		if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
			// returning here should allow the event to propagate and be handled
			// normally by the browser
			return;
		}

		// otherwise call the default behavior
		defaultOnTouchStartHandler.call(this, e);
	}
});

export const handleLockMoving = (canvas) => {
	canvas.on('object:moving', (e) => {
		// 阻止对象移动到画布外面
		let padding = 10; // 内容距离画布的空白宽度，主动设置
		var obj = e.target;
		if (obj.currentHeight > obj.canvas.height - padding * 2 ||
			obj.currentWidth > obj.canvas.width - padding * 2) {
			return;
		}
		obj.setCoords();
		if (obj.getBoundingRect().top < padding || obj.getBoundingRect().left < padding) {
			obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top + padding);
			obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left + padding);
		}
		if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height - padding || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width - padding) {
			obj.top = Math.min(
				obj.top,
				obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top - padding
			);
			obj.left = Math.min(
				obj.left,
				obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left - padding
			);
		}
	})
}
export const initAligningGuidelines = (canvas) => {

	var ctx = canvas.getSelectionContext(),
		aligningLineOffset = 5,
		aligningLineMargin = 4,
		aligningLineWidth = 1,
		aligningLineColor = 'rgb(255,255,0)',
		viewportTransform,
		zoom = 1;

	function drawVerticalLine(coords) {
		drawLine(
			coords.x + 0.5,
			coords.y1 > coords.y2 ? coords.y2 : coords.y1,
			coords.x + 0.5,
			coords.y2 > coords.y1 ? coords.y2 : coords.y1);
	}

	function drawHorizontalLine(coords) {
		drawLine(
			coords.x1 > coords.x2 ? coords.x2 : coords.x1,
			coords.y + 0.5,
			coords.x2 > coords.x1 ? coords.x2 : coords.x1,
			coords.y + 0.5);
	}

	function drawLine(x1, y1, x2, y2) {
		ctx.save();
		ctx.lineWidth = aligningLineWidth;
		ctx.strokeStyle = aligningLineColor;
		ctx.beginPath();
		ctx.moveTo(((x1 + viewportTransform[4]) * zoom), ((y1 + viewportTransform[5]) * zoom));
		ctx.lineTo(((x2 + viewportTransform[4]) * zoom), ((y2 + viewportTransform[5]) * zoom));
		ctx.stroke();
		ctx.restore();
	}

	function isInRange(value1, value2) {
		value1 = Math.round(value1);
		value2 = Math.round(value2);
		for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
			if (i === value2) {
				return true;
			}
		}
		return false;
	}

	var verticalLines = [],
		horizontalLines = [];

	canvas.on('mouse:down', function () {
		viewportTransform = canvas.viewportTransform;
		zoom = canvas.getZoom();
	});

	canvas.on('object:moving', function (e) {

		var activeObject = e.target,
			canvasObjects = canvas.getObjects(),
			activeObjectCenter = activeObject.getCenterPoint(),
			activeObjectLeft = activeObjectCenter.x,
			activeObjectTop = activeObjectCenter.y,
			activeObjectBoundingRect = activeObject.getBoundingRect(),
			activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
			activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
			horizontalInTheRange = false,
			verticalInTheRange = false,
			transform = canvas._currentTransform;

		if (!transform) return;

		// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
		// but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

		for (var i = canvasObjects.length; i--;) {

			if (canvasObjects[i] === activeObject) continue;

			var objectCenter = canvasObjects[i].getCenterPoint(),
				objectLeft = objectCenter.x,
				objectTop = objectCenter.y,
				objectBoundingRect = canvasObjects[i].getBoundingRect(),
				objectHeight = objectBoundingRect.height / viewportTransform[3],
				objectWidth = objectBoundingRect.width / viewportTransform[0];

			// snap by the horizontal center line
			if (isInRange(objectLeft, activeObjectLeft)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - aligningLineOffset)
						: (objectTop + objectHeight / 2 + aligningLineOffset),
					y2: (activeObjectTop > objectTop)
						? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
						: (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
			}

			// snap by the left edge
			if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - aligningLineOffset)
						: (objectTop + objectHeight / 2 + aligningLineOffset),
					y2: (activeObjectTop > objectTop)
						? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
						: (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
			}

			// snap by the right edge
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - aligningLineOffset)
						: (objectTop + objectHeight / 2 + aligningLineOffset),
					y2: (activeObjectTop > objectTop)
						? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
						: (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
			}

			// snap by the vertical center line
			if (isInRange(objectTop, activeObjectTop)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - aligningLineOffset)
						: (objectLeft + objectWidth / 2 + aligningLineOffset),
					x2: (activeObjectLeft > objectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
						: (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
			}

			// snap by the top edge
			if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - aligningLineOffset)
						: (objectLeft + objectWidth / 2 + aligningLineOffset),
					x2: (activeObjectLeft > objectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
						: (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
			}

			// snap by the bottom edge
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - aligningLineOffset)
						: (objectLeft + objectWidth / 2 + aligningLineOffset),
					x2: (activeObjectLeft > objectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
						: (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
				});
				activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
			}
		}

		if (!horizontalInTheRange) {
			horizontalLines.length = 0;
		}

		if (!verticalInTheRange) {
			verticalLines.length = 0;
		}
	});

	canvas.on('before:render', function () {
		canvas.clearContext(canvas.contextTop);
	});

	canvas.on('after:render', function () {
		for (var i = verticalLines.length; i--;) {
			drawVerticalLine(verticalLines[i]);
		}
		for (var i = horizontalLines.length; i--;) {
			drawHorizontalLine(horizontalLines[i]);
		}

		verticalLines.length = horizontalLines.length = 0;
	});

	canvas.on('mouse:up', function () {
		verticalLines.length = horizontalLines.length = 0;
		canvas.renderAll();
	});
}


export default fabric