import React from 'react'
import { Message, Switch, Spin, List, Button, Tag, Tooltip, Input, Icon } from 'antd'
import { get, send } from 'mulan-lib'
import WenanItem from 'lib/component/wenan-item'
import ArticleItem from 'lib/component/article-item'
import InfiniteScroll from 'react-infinite-scroller'
import BlockTitle from 'lib/component/block-title'
import WenanCardItem from 'lib/component/wenan-card-item'
import GAds from 'lib/component/gads'
import Roll from './roll'
import LoadMore from 'lib/component/load-more'
import { processRequest, trackEvent, setTitle } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: false,
      from: 0,
      max: 1,
      list: [],
      hasMore: true,
      isFull: false,
      fetched: false,
      searchType: 'word',
      tags: ['秋天'],
      isAnd: false,
      inputVisible: false,
      inputValue: '',
    }
    setTitle('关键字探索')
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 1,
      list: []
    })
  }
  handleClearAllTag() {
    this.setState({
      tags: [],
      articleList: null,
      list: [],
      fetched: false,
      form: 0,
    })
  }
  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({ tags });
  }
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    // console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  }

  saveInputRef = input => (this.input = input);

  componentDidMount() {
    window.document.body.scrollTo(0,0)
    const { location } = this.props
    const { query } = location
    const { tags } = query
    if (tags && tags.length > 0) {
      this.setState({
        tags: tags.split(',')
      }, this.handleInfiniteOnLoad.bind(this))
    }

  }
  dropSymbols(str) {
    // return str.replace(/[\w|\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, '')
    return str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, '')
  }
  dropWords(str) {
    return str.replace(/[文案|句子|的]/g, '')
  }
  handleSearchArticles() {
    const { tags } = this.state
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/search',
        data: {
          words: tags,
          from: 0,
          max: 6,
        }
      }),
      data => {
        const { list = [] } = this.state
        this.setState({
          // loading: false,
          articleList: data //list.concat(data || []),
        })
      }
    )
  }
  handleSearch() {
    const { tags, isAnd, total = 0 } = this.state
    const postData = {
      words: tags,
      type: isAnd ? 'and' : 'or',
      // from: this.state.from,
      max: this.state.max,
    }
    this.setState({
      loading: true,
    })
    if (!!total && total > 0)  {
      postData.total = total
      postData.from = ~~(Math.random() * total)
    } else {
      postData.from = 0
    }
    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan/v2/search',
        data: postData
      }),
      data => {
        // const { list = [] } = this.state
        const { list = [], total = 0 } = data
        // console.log(data)
        setTimeout(() => {
          this.setState({
            loading: false,
            total,
            list, //list.concat(data || []),
          })
        }, 1000)
      }
    )
  }
  handleInfiniteOnLoad = () => {
    let { list, from, max, updateCount } = this.state
    this.setState({
      loading: true,
      fetched: true,
      from: from + max
    })
    setTimeout(() => {
      this.handleSearch()
      if (from === 0) {
        this.handleSearchArticles()
      }
    }, 1000)
  }
  handleToggleFull() {
    const { isFull } = this.state
    // console.log(isFull)
    this.handleReset()
    this.setState({
      isFull: !isFull
    }, () => {
      setTimeout(() => {
        this.handleSearch()
      }, 1000)
    })
  }
  handleToggleSearchType() {
    const { searchType } = this.state
    this.handleReset()
    this.setState({
      searchType: searchType === 'article' ? 'word' : 'article'
    }, () => {
      setTimeout(() => {
        this.handleSearch()
      }, 1000)
    })
  }
  handleIsAndChange(checked) {
    this.setState({
      isAnd: checked
    }, this.handleRefetch.bind(this))
  }
  handleRefetch() {
    trackEvent('keyword', 'search')
    this.setState({
      list: [],
      from: 0,
      total: 0,
    }, this.handleInfiniteOnLoad.bind(this))
  }
  render() {
    const { loading = true, total = 0, list = [], articleList, tags, inputVisible, inputValue, isAnd, fetched } = this.state;
    // <GAds slot='1378111992' />
    // const { list = [], searchVal, loading, nlpwords = [], isFull, searchType } = this.state
    return (
      <div className={ `keywords ${ loading  ? 'loading' : '' }` }>
        <h1 className='child-page-title'>关键字探索</h1>
        <div className='keywords-condition'>
          <div className='tag-container'>
            <div className='tag-container-content' >
              {
                tags.map((tag, index) => {
                  const isLongTag = tag.length > 20
                  const tagElem = (
                    <Tag key={tag} closable={ true } onClose={() => this.handleClose(tag)}>
                      { isLongTag ? `${ tag.slice(0, 20) }...` : tag }
                    </Tag>
                  )
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  )
                })
              }
              {
                inputVisible && (
                  <Input
                    ref={this.saveInputRef}
                    type="text"
                    size="small"
                    style={{ width: 78 }}
                    value={inputValue}
                    maxLength={ 10 }
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputConfirm}
                    onPressEnter={this.handleInputConfirm}
                  />
                )
              }
              {
                (!inputVisible && tags.length < 3) && (
                  <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed', cursor: 'pointer' }}>
                    <Icon type="plus" /> 关键字
                  </Tag>
                )
              }
            </div>
          </div>
          <Button shape='round' disabled={ tags.length == 0 } loading={ loading } type='primary'
            onClick={ this.handleRefetch.bind(this) }>探索</Button>
          {
            tags.length > 0 && (
              <div className='keywords-condition-clear' onClick={ this.handleClearAllTag.bind(this) }><Icon type="close-circle" /></div>
            )
          }
        </div>
        <div className='keywords-action-container'>
          <span>包含关系：<Switch checked={ isAnd } onChange={ this.handleIsAndChange.bind(this) } checkedChildren="且" unCheckedChildren="或" /></span>
        </div>
        <div className='keywords-content'>
          {
            ((!loading || list.length > 0) && fetched) && (
              !loading && list.length === 0 ? (
                <div style={{ textAlign: 'center' }}>
                  无匹配，换个关键字试试，或 <a href={`/gpt?kw=${ tags.join(',') }`}>使用AI</a> 生成
                </div>
              ) : (
                <Roll total={ total } keywords={ tags } wenan={ loading ? {} : list[0] } handleRoll={ this.handleSearch.bind(this) } loading={ loading } />
              )
            )
          }
        </div>
        {
          (((!loading || list.length > 0) && fetched) && articleList && articleList.length > 0) && (
            <div className='keywords-articles'>
              <BlockTitle title='相关文章' secondary noPadding style={{ marginBottom: '.2rem' }} />
              <List
                dataSource={articleList}
                grid={{ gutter: 14, lg:3, md: 1, sm:1, xs:1 }}
                renderItem={item => (
                  <List.Item style={{ width: '100%' }} >
                    <ArticleItem item={ item } />
                  </List.Item>
                )}
              >
              </List>

            </div>
          )
        }
      </div>
    )
  }
}

export default Home


// let str = '所有的节日，并不是为了礼物而来，而是提醒我们不要忘记爱与被爱，生活需要仪式感。'
// let keywords = ['节日','礼物','爱']
//
// let matched = str.match(new RegExp(['节日','礼物','爱'].join("|"), "g"))
// matched = [...new Set(matched)]
// console.log(matched)
// matched.map(o => {
//   const rep = `<a href='${  }' title>${ o }</a>`
//   str = str.replace(new RegExp(o, "g"), `<a>${ o }</a>`)
// })
// console.log(str)


{/* <List
dataSource={list}
locale={{
  emptyText: (
    <span>
      无匹配，换个关键字试试，或 <a href={`/gpt?kw=${ tags.join(',') }`}>使用AI</a> 生成
    </span>
  ) }}
grid={{ gutter: 0, lg:3, md: 1, sm:1, xs:1 }}
loadMore={ <LoadMore title='下一批' loading={ loading }
onClick={ () => {
  trackEvent('keyword', 'next')
  this.handleInfiniteOnLoad()
} } /> }
renderItem={item => (
  <List.Item style={{ width: '100%' }} >
    <div className='keywords-content-card'>
      <WenanCardItem randomColor={ true } keywords={ tags } showUserInfo={ false } wenan={ item } style={{ height: '100%' }} showBorder={ false } animation={ true }  />
    </div>
  </List.Item>
)}
>
</List> */}