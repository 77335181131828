import React from 'react'
import { browserHistory } from 'react-router'
import './index.less'
import { Modal, Button, message, Icon, Tooltip, Popover } from 'antd'
import Clipboard from 'lib/component/copyToClipbord'
import WenanActions from '../wenan-actions'
import UserAvatar from '../user-avatar'
import { baseApiUrl } from 'config'
import { trackEvent, checkIsLight, set16ToRGB } from 'lib'


const titleStyle = {
  display: '-webkit-box',
  textAlign: 'justify',
  wordBreak: 'break-all',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  // fontWeight: 500,
}
export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isMatchKeyword: false,
    bgColor: '#FFF',
    colorList: [
      // ...['#6B9AAE','#686868','#84695A', '#88AB8D', '#73A680', '#8B514A','#4E573B', '#7CBCC8', '#778470', '#ACC9E4', '#B7D9E4' ],
      '#F9F9F9', '#F6F6F6', '#F7F7F7',
      '#FFF6F6', '#F0EFE9', //'#FFFFFF',
      '#E8F1F3', '#FBF9F7', '#F6F0DE',
      '#F9F0DF', '#F8FCFF', '#F6EEE8',
      '#FEFCF3', '#F8F8F8', '#FFF1F1',
      '#FCFDF2', '#F6FBF4', '#F4F9F9',
      '#F6F5F1', '#F4F6FF', '#F5EDF7'
    ],
    showQRScan: false
  }
  handleMatchKeywords(words) {
    const { keywords } = this.props

    let matched = words.match(new RegExp(keywords.join("|"), "g"))
    matched = [...new Set(matched)]
    // console.log(matched)
    matched.map(o => {
      const rep = `<span class='wenan-card-text-content-light'>${ o }</span>`
      words = words.replace(new RegExp(o, "g"), rep)
    })

    return words
  }
  componentWillMount() {
    const { keywords = [], randomColor = false } = this.props
    const { colorList = [] } = this.state
    const params = {}
    if (keywords.length > 0) {
      params.isMatchKeyword = true
    }
    if (randomColor) {
      const color = colorList[~~(Math.random() * colorList.length)]
      params.localStyle = {
        backgroundColor: color
      }
      params.bgColor = color
    }
    this.setState(params)
    this.handleCalcHot()
  }
  handleCalcHot() {
    const { wenan } = this.props
    const { createdTime, hot } = wenan
    const now = Date.now()
    const calcHot = ~~((now - createdTime) / 1000000)
    // console.log(calcHot, hot, hot+calcHot)
    this.setState({
      calcHot
    })
  }
  handlePopPic(pic) {
    Modal.confirm({
      title: '',
      className: 'pic-modal',
      okText: '下载图片',
      cancelText: '关闭',
      maskClosable: true,
      onOk: () => {
        window.open(pic)
      },
      content: (
        <div>
          <img src={ pic } style={{ width: '100%', boxShadow: '0 0 5px 3px rgba(0,0,0,.05)' }} />
          <div style={{ color: '#ccc', fontSize: '.12rem', textAlign: 'center', marginTop: '.06rem' }}>点击「下载图片」，右键（长按）保存</div>
        </div>
      ),
      icon: false,
      footer: false
    })
  }
  handleToUserPage(uid) {
    trackEvent('user', 'user-center-other')
    browserHistory.push(`/u/${ uid }`)
  }
  handleShowQRScan() {
    trackEvent('miniprogram', 'show-qrcode')
    this.setState({
      showQRScan: true
    })
  }
  handleMouseLeave() {
    console.log(111)
    this.setState({
      showQRScan: false
    })
  }
  render() {
    const { wenan = {}, onClick, showAd, adUnitId, thumbnail,
            type, pic, onShareClick, userinfo = {},
            keyIndex = 0, style, showBorder = true,
            animation = false, showUserInfo = true,
            randomColor = false,
          } = this.props
    const { localStyle = {}, bgColor, showQRScan = false, calcHot = 0 } = this.state
    const { headimgurl, uid, nickname } = userinfo
    const { word, hot, _id: wordId, source, opus = '', author = '' } = wenan

    const isSelection = source === 'selection'
    const { isMatchKeyword } = this.state
    //animationDelay:`${ keyIndex * 0.2 }s`
    //<div className='wenan-card-info-user-name'>{ nickname }</div>
    return (
      <div
        className={ `wenan-card ${ showBorder ? '' : 'no-border' } ${ animation ? 'animate' : '' } ${ checkIsLight(set16ToRGB(bgColor)) ? 'light' : 'dark' }` }
        style={{
          ...style,
          ...localStyle,
        }}
      >
        {
          showUserInfo && (
            <div className='wenan-card-info '>
              <div className='flex flex-justify-start flex-align-center'>
                <div className='wenan-card-info-user flex flex-justify-start flex-align-center'>
                  <div className='wenan-card-info-user-headimgurl'>
                    <UserAvatar nickname={ nickname } size='middle' avatar={ headimgurl } tips='查看足迹' onClick={ this.handleToUserPage.bind(this, uid) } />
                  </div>
                  <div>
                    <div className='wenan-card-info-user-name'>
                      {nickname}
                    </div>
                    <div className='wenan-card-info-user-operate'>
                      { type === 'copy' ? '复制了' : type === 'favorite' ? '喜欢了' : type === 'picture' ? '生成了' : '' }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )
        }
        {
          type !== 'picture' && (
            <div className='wenan-card-scan-tips' onClick={ this.handleShowQRScan.bind(this) }>
              <Tooltip placement='right' title="手机上复制">
                <img src={ require('img/scan-tips.png') } alt='文案门小程序' />
              </Tooltip>
            </div>
          )
        }

        <div className={ `wenan-card-text ${ type === 'picture' ? 'thumb' : 'word' }` }>
          {
            type === 'picture' ? (
              <div className='wenan-card-thumb' onClick={ this.handlePopPic.bind(this, pic) }>
                <img src={ thumbnail } alt={ word } />
                <div className='wenan-card-thumb-tip'>
                  <Icon type="search" />
                </div>
              </div>
            ) : (
              isMatchKeyword ? (
                <div className='wenan-card-text-content' dangerouslySetInnerHTML={{ __html: this.handleMatchKeywords(word) }}></div>
              ) : (
                <div className='wenan-card-text-content' >
                  { isSelection && author && `${ author }：` }
                  { `${ word }` }
                </div>
              )
            )
          }
        </div>
        {
          showQRScan && (
            <div className='wenan-card-scan' onClick={ this.handleMouseLeave.bind(this) } onMouseLeave={ this.handleMouseLeave.bind(this) }>
              <img alt='关注文案门'
                   src={ `${ baseApiUrl }/api/admin/common/qrcode?page=pages/index/index&scene=${ wordId }` } />
            </div>
          )
        }

        <div className='wenan-card-hot'>
          <Icon type="fire" /> { hot + calcHot }
        </div>
        <div className='wenan-card-action'>
          <WenanActions wenan={ wenan } visible={ true } source='quan' justify='between' isfull />
        </div>

      </div>
    )
  }
}


// <div className='wenan-card-info-user-operate'>
//   { type === 'picture'
//       ? '生成了'
//       : type === 'copy'
//         ? '复制了'
//         : type === 'favorite'
//           ? '收录了'
//           : '复制了'
//    }
// </div>
//`${ thumbnail }`

// <div className='wenan-card-info-action' onClick={ () => {
//   // console.log(word, '----1')
//   onShareClick(word)
// } }>
//   <Button openType='share' >
//     <IconFont name='fenxiang_o' size={ 50 } color='#ccc' />
//   </Button>
// </div>
