import React from 'react'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
  }

  render() {
    const { children, value, currentKey, onClick, pic, style } = this.props
    return (
      <div key={ value } onClick={ onClick }
           style={ style || {} }
           className={ `poster-tools-content-action-item ${ currentKey === value ? 'active' : '' } ${ pic ? 'pic' : '' }` } >
        { pic ? (
          <img src={ require(`img/poster/${ value }.png`) } />
        ) : children }
      </div>
    )
  }
}

export default Home
