const clientWidth = window.document.documentElement.clientWidth
const fixMobile = n => clientWidth < 1000 ? n * 2 : n

export const textConfig = {
    fontSize: fixMobile(20),
    stroke: '#000000', // 描边颜色
    strokeWidth: 8, // 描边宽度
    strokeLineCap: 'round',
    strokeLineJoin: 'round',
    paintFirst: 'stroke',
    charSpacing: fixMobile(20),
    fill: '#ffffff', // 填充颜色
    left: fixMobile(50),
    top: fixMobile(50),
    fontWeight: 'normal',
    fontStyle: 'normal',
    minScaleLimit: 0.3,
    fontSmoothing: 'antialiasing',
    fontFamily: 'Noto Sans SC',
    fontWeight: 'bold',
}

export const imgConfig = {
    left: fixMobile(100),
    top: fixMobile(100),
    angle: 0,
    centeredRotation: true,
    originX: 'center',
    originY: 'center'
}