import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import { send } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import InfiniteScroll from 'react-infinite-scroller'
import ArticleItem from 'lib/component/article-item'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 12,
      list: [],
      hasMore: true
    }
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 12,
      list: []
    })
  }
  componentDidMount() {
    setTimeout(this.handleGetList.bind(this), 1000)
  }
  handleGetList() {
    // send('/api/admin/common/func', {
    //   url: 'article/rank',
    //   data: {
    //     len: 6
    //   }
    // })
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/rank',
        data: {
          len: 8
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        this.setState({
          loading: false,
          list: list.concat(data || []),
        })
      }
    )
    // this.handleReset()
    // processRequest(
    //   send('/api/admin/common/func', {
    //     url: 'article/list',
    //     data: {
    //       from: 0,
    //       max: 8,
    //     }
    //   }),
    //   data => {
    //     const { list } = this.state
    //     // const { word } = data
    //     this.setState({
    //       loading: false,
    //       list: list.concat(data || []),
    //     })
    //   }
    // )
  }

  handleInfiniteOnLoad = () => {
    let { list } = this.state
    this.setState({
      loading: true,
    });
    // if (list.length > 24) {
    //   message.warning('已加载完所有数据')
    //   this.setState({
    //     hasMore: false,
    //     loading: false,
    //   });
    //   return
    // }
    this.handleGetList()
  };

  render() {
    const { loading = true, pexels = { src: '' } } = this.state
    return (
      <div className={ `article-block ${ loading  ? 'loading' : '' }` }>
        <BlockTitle title="句集" extra='换一下' secondary noPadding arrowType='refreash' arrowStyle={{ width: '15px' }}
                    onClick={
                      () => {
                        trackEvent('main', 'articles')
                        this.handleReset()
                        this.handleGetList()
                        // browserHistory.push('/articles')
                      }
                    } />
        <div className='article-block-content'>
          {
            loading && (
              <List
                dataSource={[1,2,3,4,5,6,7,8]}
                grid={{ gutter: 8, lg:4, md: 2, sm:2, xs:2 }}
                renderItem={ item => (
                  <List.Item style={{ width: '100%', marginBottom: '.12rem' }} >
                    <div key={`article-block-skeleton-${ item }`} className='article-block-item' >
                      <div style={{
                        height: '1.5rem',
                        width: '100%',
                        backgroundColor: '#f2f2f2',
                        marginBottom: '.12rem'
                      }}></div>
                      <Skeleton
                        paragraph={{row: 2}}
                        title={false}
                        loading={ loading }
                        shape='square'
                        size='large'
                        active />
                    </div>
                  </List.Item>
                )}
              >
              </List>
            )
          }
          {
            !loading && (
              <List
                dataSource={this.state.list}
                grid={{ gutter: 8, lg:4, md: 2, sm:2, xs:2 }}
                renderItem={item => (
                  <List.Item style={{ width: '100%' }} >
                    <ArticleItem item={ item } mode='lite' />
                  </List.Item>
                )}
              >
              </List>
            )
          }

        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

export default Home
