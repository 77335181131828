import React from 'react'
import { browserHistory } from 'react-router'
import { send, getStore, setStore } from 'mulan-lib'
import { Badge, Button, message, Icon, Tooltip, Popover } from 'antd'
import Clipboard from 'lib/component/copyToClipbord'
import { processRequest, trackEvent } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

const ButtonGroup = Button.Group

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    shareList: [
      {
        name: '微博',
        type: 'weibo',
        icon: require('img/share/weibo.png'),
      },
      {
        name: '推特',
        type: 'twitter',
        icon: require('img/share/tweet.png'),
      },
      {
        name: '豆瓣',
        type: 'douban',
        icon: require('img/share/douban.png'),
      },
      {
        name: '小程序',
        type: 'minip',
        icon: require('img/share/minip.png'),
      },
    ]
  }
  componentDidMount() {

  }
  handleCreateEvent(wenan, type) {
    const userinfo = getStore('userinfo')
    if (userinfo) {
      const { uid } = userinfo
      const { _id, ...params } = wenan
      const postData = {
        wenan,
        userinfo,
        type,
        uid,
        wenanId: _id
      }
      send('/api/admin/common/func', {
        url: 'event/create',
        data: postData,
      })
    }
  }
  handleToParsing() {
    const { source, wenan } = this.props
    trackEvent(source, 'parsing')
    setStore('wenan', wenan, 'session')
    window.open(`/p/${ wenan._id }`)
    // browserHistory.push()
  }
  handleToPoster() {
    const { source, wenan } = this.props
    trackEvent(source, 'poster')
    setStore('wenan', wenan, 'session')
    window.open(`/poster/${ wenan._id }`)
    // browserHistory.push()
  }
  handleAfterCopy() {
    const { source, wenan } = this.props
    trackEvent(source, 'copy')
    this.handleCreateEvent(wenan, 'copy')
    message.success("复制成功", 2)
  }
  handleFavorite() {
    const { source, wenan } = this.props

    const userinfo = getStore('userinfo')
    if (userinfo) {
      const { uid } = userinfo
      const { _id } = wenan
      processRequest(
        send('/api/admin/common/func', {
          url: 'favorite/create',
          data: {
            wenan,
            uid,
            wenanId: _id,
          },
        }),
        data => {
          this.handleCreateEvent(wenan, 'favorite')
          trackEvent(source, 'favorite')
          message.success("收录成功", 2)
        }
      )
    }
  }
  handleShareTo(target) {
    const { wenan, source } = this.props
    const { word = '', id } = wenan
    trackEvent(source, `share-${ target }`)
    switch (target) {
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?via=site_wenanmen&text=${ `${ word } \n\n #文案门` }`)
        break
      case 'weibo':
        window.open(`https://service.weibo.com/share/share.php?title=${ word } \n\n #文案门`)
        break
      case 'douban':
        window.open(`https://www.douban.com/share/service?href=https://www.wenanmen.com/p?id=${ id }&name=${ word }&starid=0&aid=0&style=11`)
        break
      default: break
    }
  }
  render() {
    const { visible, wenan, onClick, source = 'roll', justify = 'end', isfull = false } = this.props
    const { word, _id: wordId } = wenan || {}
    // <Icon type="monitor" />

    return (
      <div
        className={ `wenan-action-group ${ !visible ? 'loading' : '' } ${ justify } ${ isfull ? 'full' : '' }` } >


        <Tooltip placement='top' title="解析文案">
          <Button  onClick={ this.handleToParsing.bind(this) } type='default' style={{ marginLeft: 0 }} shape="circle" className='wenan-action-group-btn' >
            <Icon type='file-search' />
          </Button>
        </Tooltip>
        <ButtonGroup>
          <Clipboard value={ `${ word } \n\n #文案门` }
                     completeCopy={ this.handleAfterCopy.bind(this) }>
            <Tooltip placement='top' title="复制文案">
              <Button icon='copy' className='wenan-action-group-btn' />
            </Tooltip>
          </Clipboard>
          <Tooltip placement='top' title="收录文案">
            <Button onClick={ this.handleFavorite.bind(this) } icon='heart' className='wenan-action-group-btn' />
          </Tooltip>
          <Popover placement='top' title="分享文案" content={
              (
                <div className='wenan-action-group-share'>
                  {
                    this.state.shareList.map(o => {
                      const { icon, type, name } = o
                      return (
                        <div key={ `${ wordId }-${ type }` } className='wenan-action-group-share-item'>
                          {
                            type === 'minip' ? (
                              <Popover placement='bottom' title={ '微信扫码分享' } content={
                                  (
                                    <div className='flex flex-justify-center'>
                                      <img alt='关注文案门'
                                          src={ `${ baseApiUrl }/api/admin/common/qrcode?page=pages/index/index&scene=${ wordId }` } style={{ width: '120px' }} />
                                    </div>
                                  )
                                }>
                                <img src={ icon } />
                              </Popover>
                            ) : (
                              <Tooltip placement='bottom' title={ name }>
                                <img src={ icon } onClick={ this.handleShareTo.bind(this, type) } />
                              </Tooltip>
                            )
                          }

                        </div>
                      )
                    })
                  }
                </div>
              )
            }>
            <Button icon='share-alt' className='wenan-action-group-btn' />
          </Popover>
        </ButtonGroup>
        <Tooltip placement='top' title="制作海报">
          <Button type='default' icon='picture' onClick={ this.handleToPoster.bind(this) } shape="circle" className='wenan-action-group-btn' />
        </Tooltip>


      </div>
    )
  }
}



// <Popover
//   placement="bottom"
//   content={
//     <div style={{ width: '100%', textAlign: 'center' }}>
//       {
//         !!wordId
//           ? (
//             <img alt='关注文案门'
//                  src={  }
//                  width='100' />
//           )
//           : '暂未获取到文案'
//       }
//
//     </div>
//   }
//   title="微信扫一扫，立即制作"
//   trigger="click"
//   >
//
// </Popover>
