import React from 'react'
import { browserHistory } from 'react-router'
import './index.less'
import { moment, send } from 'mulan-lib'
import { Modal, Button, Icon, Tooltip } from 'antd'
import WenanActions from '../wenan-actions'
import UserAvatar from '../user-avatar'
import { trackEvent, processRequest, set16ToRGB } from 'lib'


const titleStyle = {
  display: '-webkit-box',
  textAlign: 'justify',
  wordBreak: 'break-all',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  // fontWeight: 500,
}
export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {}
  componentWillMount() {}

  handleToUserPage(uid) {
    trackEvent('quan', 'user-center-other')
    browserHistory.push(`/u/${ uid }`)
  }
  handlePopPic(pic) {
    Modal.confirm({
      title: '',
      className: 'pic-modal',
      okText: '下载图片',
      cancelText: '关闭',
      maskClosable: true,
      onOk: () => {
        var a = document.createElement('a')
        a.download = pic.split('/').pop()
        a.href = pic
        a.target = '_blank'
        a.click();
      },
      content: (
        <div>
          <img src={ pic } style={{ width: '100%', boxShadow: '0 0 5px 3px rgba(0,0,0,.05)' }} />
          <div style={{ color: '#ccc', fontSize: '.12rem', textAlign: 'center', marginTop: '.06rem' }}>点击「下载图片」，或右键（长按）保存</div>
        </div>
      ),
      icon: false,
      footer: false
    })
  }
  handleToArticle(articleId) {
    trackEvent('quan', 'article-detail')
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/rid',
        data: {
          rid: articleId
        }
      }),
      data => {
        const { _id } = data
        window.open(`https://www.wenanmen.com/pages/s/${ _id }.html`)
      }
    )
  }
  render() {
    const { obj = {}, isHideUserInfo } = this.props
    console.log(obj)
    const { _id, userinfo, uid, wenan, createdTime, type, thumbnail = '', pic = '' } = obj
    const { nickname, headimgurl } = userinfo
    const { word, articleTitle, articleId, author, source } = wenan
    const isSelection = source === 'selection'

    return (
      <div key={ _id } className='wenan-card2'>
        <div className={ `wenan-card2-user flex flex-justify-between flex-align-center ${ isHideUserInfo ? 'hide' : '' }` }>
          <div className='flex flex-justify-start flex-align-center'>
            <UserAvatar nickname={ nickname } avatar={ headimgurl } tips='查看足迹' showSource={ true } onClick={ this.handleToUserPage.bind(this, uid) } />
            <div>
              <div className='wenan-card2-user-nickname'>{ nickname }</div>
              <div className={`wenan-card2-user-action ${ type }`}>{
                  type === 'copy' ? '复制了' : type === 'favorite' ? '喜欢了' : type === 'picture' ? '生成了' : ''
                }</div>
            </div>

          </div>
          <div className='wenan-card2-user-time'>{ moment('MM/DD HH:mm')(createdTime) }</div>
        </div>

        <div key={ _id } className='wenan-card2-content'>
          { isSelection && author && `${ author }：` }
          {word}

          { type === 'picture' && thumbnail && (
              <div className='wenan-card2-content-thumb' onClick={ this.handlePopPic.bind(this, pic) }>
                <img src={ thumbnail } alt={ word } />
                <div className='wenan-card2-content-thumb-tip'>
                  <Icon type="search" />
                </div>
              </div>
            )
          }
        </div>

        <div className='wenan-card2-action'>
          {
            articleTitle && articleId && (
              <Tooltip placement='top' title={ <span style={{ fontSize: '.1rem' }}>{ `出自『${articleTitle}』` }</span> }>
                <Button onClick={ this.handleToArticle.bind(this, articleId) } type='ghost' shape="round" className='roll-action-group-btn' >
                  <span><Icon type='link' /> 文章</span>
                </Button>
              </Tooltip>
            ) || <div />
          }
          <div><WenanActions wenan={ wenan } visible={ true } source='quan' justify='between' isfull /></div>
        </div>
      </div>
    )
  }
}


// <div className='wenan-card-info-user-operate'>
//   { type === 'picture'
//       ? '生成了'
//       : type === 'copy'
//         ? '复制了'
//         : type === 'favorite'
//           ? '收录了'
//           : '复制了'
//    }
// </div>
//`${ thumbnail }`

// <div className='wenan-card-info-action' onClick={ () => {
//   // console.log(word, '----1')
//   onShareClick(word)
// } }>
//   <Button openType='share' >
//     <IconFont name='fenxiang_o' size={ 50 } color='#ccc' />
//   </Button>
// </div>
