import React from 'react'
import { Button, Icon, message } from 'antd'
import { browserHistory } from 'react-router'
import { getStore } from 'mulan-lib'
import { trackEvent } from 'lib'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isLocal: true,
    isLogin: false,
  }
  componentDidMount() {
    this.checkUser()
  }
  checkUser() {
    const userinfo = getStore('userinfo')
    const utype = getStore('utype')
    const uid = getStore('uid')
    // console.log(userinfo, utype)
    if (userinfo) {
      this.setState({
        isLocal: utype === 'local',
        isLogin: true,
        userinfo,
        uid,
      })
    } else {
      setTimeout(this.checkUser.bind(this), 500)
    }
  }
  handleToUserPage() {
    // const uid = getStore('uid')
    trackEvent('user', 'user-center')
    const uid = getStore('uid')
    // message.warn('即将上线')
    window.location.href = `/u/${ uid }`
    // browserHistory.push()
  }
  handleBindAccount() {
    // trackEvent('user', 'bind-user')
    // message.warn('即将上线')
    // <div className='user-menu-content-local-action'><Button type='primary' onClick={ this.handleBindAccount.bind(this) }>绑定远程账户</Button></div>
  }
  render() {
    const { isLogin, isLocal, userinfo = {} } = this.state
    const { nickname = '' } = userinfo
    return (
      <div className='user-menu-content' >
        {
          isLogin && (
            isLocal ? (
              <div className='user-menu-content-local' >
                <div className='user-menu-content-local-nickname' >
                  <div className='user-menu-content-local-nickname-content' >
                    { nickname }
                  </div>
                </div>
                <div className='user-menu-content-local-tips' ><Icon type="info-circle" /> 本地账户，数据只在本浏览器有效</div>
                <div className='user-menu-content-local-action'><Button type='ghost' onClick={ this.handleToUserPage.bind(this) }>进入个人中心</Button></div>
              </div>
            ) : (
              <div className='user-menu-content-online' >
                <div className='user-menu-content-online-nickname' >{ nickname }</div>
                <div className='user-menu-content-online-action'><Button type='ghost' onClick={ this.handleToUserPage.bind(this) }>进入个人中心</Button></div>
              </div>
            )
          )
        }
      </div>

    )
  }
}
