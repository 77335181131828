import React from 'react'
import { Button, Col, Row, List, Spin, Avatar, message, Icon, Popover, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import { send } from 'mulan-lib'
import { trackEvent } from 'lib'
import BlockTitle from 'lib/component/block-title'
import GAds from 'lib/component/gads'
import './index.less'

class SiderGPT extends React.Component {
  constructor(props) {
    super(props)
  }
  handleReset() {
  }
  componentDidMount() {}
  handleClick(target) {
    trackEvent('sider', `gpt${ target === 'wa' ? '' : `-${ target }` }`)
    // browserHistory.push('/gpt')
    window.open(`https://www.wenanmen.com/gpt${ target === 'wa' ? '' : `/${ target }` }`)
  }

  render() {
    return (
      <div className={ `gpt-sider` } >
        <div className='gpt-sider-title'>AI 帮你写文案</div>
        <div className='gpt-sider-target'>
          {
            [
              {
                name: '通用', icon: 'wa', color: '#bf63ea', tips: '风格·情绪·篇幅'
              },
              {
                name: '小红书', icon: 'xhs', color: '#ff2741', tips: '标题·正文·标签'
              },
              {
                name: '重写', icon: 'cx', color: '#1eb4bb', tips: '雷同·创意·情感'
              },
            ].map(o => {
              const { name, icon, color, tips } = o
              return (
                <div key={ `sider-${ name }` } className='gpt-sider-target-item' onClick={ this.handleClick.bind(this, icon) }>
                  <div>
                    <div className='gpt-sider-target-item-icon'>
                      <img src={ require(`img/active/tab/${ icon }.png`) } />
                    </div>
                    <div className='gpt-sider-target-item-name' style={{ color }}>{name}</div>
                  </div>
                </div>
              )
            })
          }
        </div>

      </div>
    )
  }
}

export default SiderGPT
