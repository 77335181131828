import React from 'react'
import { Message, message, Switch, Spin, List, Button, Tag, Tooltip, Input, Icon } from 'antd'
import { get, send, getStore } from 'mulan-lib'
import WenanItem from 'lib/component/wenan-item'
import ArticleItem from 'lib/component/article-item'
import BlockTitle from 'lib/component/block-title'
import WenanActions from 'component/wenan-actions'
import WenanCardItem from 'lib/component/wenan-card-item'
import LoadMore from 'lib/component/load-more'
import { processRequest, trackEvent, setTitle } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }
  componentWillMount() {
    const { params, location = {} } = this.props
    const { query = {} } = location 
    let { id } = params
    const { id: queryId } = query
    if (!!!id && !!queryId) {
      id = queryId
    }
    // const { word, id } = query
    const wenan = getStore('wenan', 'session')
    let _id = 0
    if (!!wenan && !!wenan._id) {
      _id = wenan._id
    }
    // message.warn('服务升级中，日后会正常开放，感谢！', 30)
    // return
    // console.log(wenan,  this.props, '=======wenan====')
    if (!!wenan && _id === id) {
      const { word } = wenan
      setTitle(`解析：${ word }`)

      this.setState({
        wenan,
        wenanId: id,
      }, () => {
        this.handleGetKeyword()
        this.handleGetSentiment()
      })
    } else {
      this.setState({
        // word,
        wenanId: id,
        loading: true
      }, this.handleGetWenan.bind(this))
    }
  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
  }
  handleGetWenan() {
    const { wenanId } = this.state

    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan',
        data: {
          id: wenanId,
        }
      }),
      data => {
        // console.log(data)
        const { word } = data

        setTitle(`解析：${ word }`)

        this.setState({
          wenan: data,
        }, () => {
          this.handleGetKeyword()
          this.handleGetSentiment()
        })
      }
    )
  }
  handleGetKeyword() {
    const { wenan = {} } = this.state
    const { word = '' } = wenan
    processRequest(
      get('/api/admin/common/kw', { word }),
      data => {
        this.setState({
          tags: data,
          loading: false
        }, () => {
          this.handleSearchArticles()
          this.handleSearch()
        })
      }
    )
  }
  handleGetSentiment() {
    const { wenan = {} } = this.state
    const { word = '' } = wenan
    processRequest(
      get('/api/admin/common/sentiment', { word }),
      data => {
        this.setState({
          sentiment: data,
          loading: false
        })
      }
    )
  }
  handleSearchArticles() {
    const { tags } = this.state
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/search',
        data: {
          words: tags,
          from: 0,
          max: 3,
        }
      }),
      data => {
        const { list = [] } = this.state
        this.setState({
          loading: false,
          articleList: data //list.concat(data || []),
        })
      }
    )
  }
  handleSearch() {
    const { tags, isAnd } = this.state
    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan/search',
        data: {
          words: tags,
          type: 'or',
          from: 0,
          max: 9,
        }
      }),
      data => {
        const { list = [] } = this.state
        this.setState({
          loading: false,
          relatedList: data //list.concat(data || []),
        })
      }
    )
  }
  handleToSingleKeyword(tag) {
    trackEvent('parsing', 'single-keyword')
    window.open(`/kw?tags=${ tag }`)
  }
  handleToKeywords() {
    const { tags } = this.state
    trackEvent('parsing', 'keyword')
    window.open(`/kw?tags=${ tags.join(',') }`)
  }

  render() {
    const { loading = true, relatedList = [], articleList = [], sentiment, list = [], tags = [], wenan = {} } = this.state;
    const { word, author } = wenan
    // const { list = [], searchVal, loading, nlpwords = [], isFull, searchType } = this.state
    // onClick={ this.handleToKeywords.bind(this) } extra={ <span><Icon type="fire" /> 探索</span> } showArrow={ true }
    //  extra={ tags.length === 0 ? <span onClick={ this.handleGetKeyword.bind(this) }><Icon type="reload" /> 刷新</span> : '' } 
    return (
      <div className={ `parsing ${ loading  ? 'loading' : '' }` }>
        <h1 className='child-page-title'>文案解析</h1>
        <div className='parsing-layer'>

          <div className='parsing-content'>
            <div className='wp100'>
              <div><img src={ require('img/common/l-yin.png') } style={{ width: '.3rem' }} /></div>
              <div className='parsing-content-word'>
                <div>
                  { author && <span className='parsing-content-word-author'><a href='#' title={ author }>{ author }</a>：</span> }{word}
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                <img src={ require('img/common/r-yin.png') } style={{ width: '.3rem' }} />
              </div>
            </div>
          </div>
          <div className='parsing-action'>
            <WenanActions wenan={ this.state.wenan } visible={ !loading } source='roll' />
          </div>
          <div className='parsing-fenxi'>
            <div className='parsing-fenxi-item'>
              <BlockTitle title='关键字' secondary noPadding showArrow={ false } />

              <div className='parsing-fenxi-item-tags'>
                {
                  tags.length === 0 && (
                    '暂无关键字'
                  )
                }
                {
                  tags.map(o => {
                    return (<div key={o} className='parsing-fenxi-item-tag' onClick={ () => this.handleToSingleKeyword(o) } title={ `探索：${ o }` }>{ o }</div>)
                  })
                }
              </div>
            </div>

            <div className='parsing-fenxi-item'>
              <BlockTitle title='情感分析' secondary noPadding />

              <div className={`parsing-fenxi-item-sentiment ${ sentiment }`} style={{ marginTop: '.2rem' }}>
                {
                  sentiment ?
                  (sentiment === 'negative'
                    ? '负面情感'
                    : sentiment === 'positive'
                      ? '正面情感'
                      : sentiment === 'neutral'
                        ? '中性'
                        : '中性'
                    ) : '分析中...'
                }
              </div>
            </div>

          </div>
        </div>
        {
          articleList.length > 0 && (
            <div className='parsing-layer'>
              <div className='parsing-articles'>
                <BlockTitle title='命中文章' secondary noPadding style={{ marginBottom: '.2rem' }} />
                {
                  articleList && articleList.length > 0 && (
                    <List
                      dataSource={articleList}
                      grid={{ gutter: 14, lg:3, md: 1, sm:1, xs:1 }}
                      renderItem={item => (
                        <List.Item style={{ width: '100%' }} >
                          <ArticleItem item={ item } />
                        </List.Item>
                      )}
                    >
                    </List>
                  )
                }
              </div>
            </div>
          )
        }
        {
          relatedList.length > 0 && (
            <div className='parsing-layer'>
              <div className='parsing-related'>
                <BlockTitle title='命中文案' secondary noPadding style={{ marginBottom: '.2rem' }} />
                {
                  relatedList && relatedList.length > 0 && (
                    <List
                      dataSource={relatedList}
                      locale={{ emptyText: '无匹配，换个关键字试试' }}
                      grid={{ gutter: 0, lg:3, md: 1, sm:1, xs:1 }}
                      loadMore={ <LoadMore title='更多文案' loading={ loading }
                      onClick={ this.handleToKeywords.bind(this) } /> }
                      renderItem={item => (
                        <List.Item style={{ width: '100%' }} >
                          <div className='keywords-content-card'>
                            <WenanCardItem randomColor={ true } keywords={ tags } showUserInfo={ false } wenan={ item } style={{ height: '100%' }} showBorder={ false } animation={ true }  />
                          </div>
                        </List.Item>
                      )}
                    >
                    </List>
                  )
                }
              </div>
            </div>
          )
        }

        {this.state.loading && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

export default Home
