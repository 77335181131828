import React from 'react'
import { List, Modal, Icon, Spin, Skeleton, Input } from 'antd'
import { send, moment } from 'mulan-lib'
import { browserHistory } from 'react-router'
import BlockTitle from 'lib/component/block-title'
import ArticleItem from 'lib/component/article-item'
import LoadMore from 'lib/component/load-more'
import GAds from 'lib/component/gads'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

const { Search } = Input

class Articles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 9,
      list: [],
      hasMore: true
    }
    setTitle('近日文章')
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 9,
      list: []
    })
  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
    this.handleGetList()
  }
  handleGetList() {
    const { from, max } = this.state
    const topCount = 0
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/list',
        data: {
          from,
          max: from === 0 ? max + topCount : max,
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        if (data.length === max + topCount) {
          const topList = data.splice(0, topCount)
          this.setState({
            loading: false,
            list: list.concat(data || []),
            topList,
          })
        }
        this.setState({
          loading: false,
          list: list.concat(data || [])
        })
      }
    )
  }

  handleInfiniteOnLoad = () => {
    trackEvent('articles', 'next')
    let { list, from, max, updateCount } = this.state

    if (from >= 18) {
      Modal.info({
        title: '更多请关注公众号：文案门',
        content: (
          <div style={{ marginTop: '.5rem' }}>
            <img alt='关注文案门' src={ require('img/qrcode.jpg') } style={{ width: '100%' }} />
          </div>
        ),
        onOk() {},
      })
      return
    }

    this.setState({
      loading: true,
      from: from + max
    }, this.handleGetList.bind(this))
  }

  render() {
    const { loading = true, hasMore, list = [], topList = [] } = this.state
    // <GAds slot='8345176724' />
    // <Search size='small' placeholder='请输入关键字' className='article-list-search' />
    return (
      <div className={ `article-list ${ loading  ? 'loading' : '' }` }>

          <h1 className='child-page-title'>
            日更文章

          </h1>


        <div className='article-list-content'>
          {
            (!loading || list.length > 0) && (
              <List
                dataSource={list}
                grid={{ gutter: 16, lg:3, md: 2, sm:2, xs:2 }}
                loadMore={ <LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } /> }
                renderItem={item => (
                  <List.Item style={{ width: '100%' }} >
                    <ArticleItem item={ item } />
                  </List.Item>
                )}
              >
              </List>
            )
          }

        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

export default Articles
