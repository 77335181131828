import React from 'react'
import { Button } from 'antd'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  render() {
    const { loading, onClick, title = '查看更多' } = this.props
    return (
        <div className='load-more'>
          {
            !loading
              ? (<Button size='large' icon='eye' onClick={ onClick }>{ title }</Button>)
              : <div>数据加载中...</div>
          }
        </div>
    )
  }
}
