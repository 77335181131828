import React from 'react'
import BlockTitle from 'lib/component/block-title'
import WenanActions from 'component/wenan-actions'
import { Badge, Button, message, Icon, Tooltip, Popover } from 'antd'
import { get, send } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import { processRequest, trackEvent } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      updateCount: 0,
      pic: ~~(Math.random() * 11 + 1)
    }
  }
  componentDidMount() {
    this.rank()
  }
  rank() {
    trackEvent('roll-shi', 'next')
    processRequest(
      send('/api/admin/common/func', {
        url: 'tang/rank',
        data: {
          type: '300'
        }
      }),
      data => {
        setTimeout(() => {
          const { contents, dynasty, title, paragraphs, author = '佚名' } = data

          this.setState({
            loading: false,
            title,
            dynasty,
            author,
            paragraphs,
          })
        }, 1500)
      }
    )
  }
  roll() {
    this.setState({
      word: '加载中...',
      wordId: '',
      loading: true,
    }, () => {
      this.rank()
    })
  }
  handleToArticle(articleId) {
    processRequest(
      send('/api/admin/common/func', {
        url: 'article/rid',
        data: {
          rid: articleId
        }
      }),
      data => {
        const { _id } = data
        window.open(`https://www.wenanmen.com/pages/s/${ _id }.html`)
      }
    )
  }
  render() {
    const { loading, pic = 1, title, author = '佚名', dynasty, paragraphs = [] } = this.state
    return (
      <div className={ `roll-shi flex flex-justify-between flex-align-center` } >
        <div className='roll-shi-pic'>
          <img src={ require(`img/shi/${ pic }.jpg`) }  />
        </div>
        <div className='roll-shi-content'>
          <div className={ `roll-shi-content-head ${ loading ? 'hide' : '' } `}>
            <div className='roll-shi-content-head-title'>
              { title }
            </div>
            <div className='roll-shi-content-head-author'>
              <span className='roll-shi-content-head-author-dynasty'>{dynasty}</span>
              <span>{ author }</span>
            </div>
          </div>
          <div className='flex flex-justify-center flex-align-center wp100'>
            <div className='roll-shi-content-word'>

              {
                loading ? '加载中...' : (
                  paragraphs.reduce((list, curr, i) => {
                    return [...list, ...(curr.split(/[;；?？]/g).map((o, j) => {
                      return (
                        <div key={ `line-${ i }-${ j }` } className='roll-shi-content-word-line'>{ o }</div>
                      )
                    }))]
                  }, [])
                )
              }
            </div>
          </div>
          <div className='roll-shi-content-footer flex flex-justify-center flex-align-center'>
            <Clipboard value={ `${ title }\n${ dynasty } ${ author }\n${ paragraphs.join('\n') } \n\n #文案门` }
                       completeCopy={
                         () => {
                           trackEvent('roll-shi', 'copy')
                           message.success('复制成功')
                         }
                       }>
              <Tooltip placement='top' title="复制文案">
                <Button type='default' disabled={ loading } style={{ marginRight: '.24rem' }} shape="circle" className={ `roll-shi-content-footer-copy ${ loading ? 'hide' : '' }` } >
                  <Icon type='copy'  />
                </Button>
              </Tooltip>
            </Clipboard>
            <Button onClick={ this.roll.bind(this) } loading={ loading } disabled={ loading }
                    type='primary'
                    shape="round" icon='sync'></Button>

          </div>
        </div>

      </div>
    )
  }
}

export default Home
