import React from 'react'
import { Popover } from 'antd'
import { getStore } from 'mulan-lib'
import UserAvatar from '../user-avatar'
import UserMenuContent from './user-menu-content'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isLogin: false,
  }
  componentDidMount() {
    this.checkUser()
  }

  checkUser() {
    const userinfo = getStore('userinfo')
    const utype = getStore('utype')
    // console.log(userinfo, utype)
    if (userinfo) {
      this.setState({
        isLogin: true,
        userinfo,
      })
    } else {
      setTimeout(this.checkUser.bind(this), 500)
    }
  }
  render() {
    const { isLogin, isLocal, userinfo = {} } = this.state
    const { nickname = '', headimgurl = '' } = userinfo
    return (
      <Popover placement="bottomRight" forceRender={true} content={ <UserMenuContent /> } trigger="hover">
        <div className='user-menu'>
          {
            isLogin && (<UserAvatar showSource={ false } nickname={ nickname } avatar={ headimgurl } />)
          }
        </div>
      </Popover>

    )
  }
}
