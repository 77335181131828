import React from 'react'
import { Col, Row, List, Spin, Avatar, message, Icon, Popover, Skeleton } from 'antd'
import { browserHistory } from 'react-router'
import { send } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import BlockTitle from 'lib/component/block-title'
import WenanCardItem from 'lib/component/wenan-card-item'
import InfiniteScroll from 'react-infinite-scroller'
import { processRequest, trackEvent } from 'lib'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 10,
      list: [],
      hasMore: true
    }
  }
  componentDidMount() {
    setTimeout(this.handleGetList.bind(this), 1500)
    // this.handleGetList()
  }
  handleTransPic(picList, cb) {
    const fileList = picList.reduce((list, curr) => {
      const { pic, thumbnail } = curr
      list.push(pic)
      list.push(thumbnail)
      return list
    }, [])
    //
    processRequest(
      send('/api/admin/common/func', {
        url: 'event/trans/pic',
        data: {
          fileList,
        }
      }),
      data => {
        cb(data || [])
      }
    )
  }
  handleGetList() {
    processRequest(
      send('/api/admin/common/func', {
        url: 'event/list',
        data: {
          from: 0,
          max: 10,
        }
      }),
      data => {
        const { list } = this.state

        const picList = data.filter(o => (o.type === 'picture'))
        // console.log(list)

        if (picList.length === 0) {
          this.setState({
            loading: false,
            list: data,
          })
        } else {
          this.handleTransPic(picList, reply => {
            // console.log(reply)
            const picList = reply.reverse()
            this.setState({
              loading: false,
              list: list.concat(data.map(o => {
                if(o.type === 'picture') {
                  o.pic = picList.pop()
                  o.thumbnail = picList.pop()
                }
                return o
              })),
            })
          })
        }


      }
    )
  }

  render() {
    const { loading = true, list = [] } = this.state
    return (
      <div className={ `quan ${ loading  ? 'loading' : '' }` }>
        <BlockTitle title="圈子动态" extra='查看更多' secondary noPadding
                    onClick={
                      () => {
                        trackEvent('main', 'quan')
                        browserHistory.push('/quan')
                      }
                    } />
        <div className='quan-container'>
          <div className='quan-container-content'>
            {
              loading && (
                [1,2,3,4,5,6,7,8,9,10].map(o => {
                  return (
                    <div key={`quan-skeleton-${ o }`} className='quan-container-content-card' style={{ padding: '.12rem' }}>
                      <Skeleton paragraph={{ rows: 5 }} loading={ loading } active />
                    </div>
                  )
                })
              )
            }
            {
              !loading && list.map(o => {
                // onThumbClick={ () => { wenanEventStore.handlePopPic(o.pic) } }
                //                         onShareClick={ title => this.handleShareClick(title, o.wenan._id) }
                //                         onClick={ () => wenanStore.handlePopDetail(o.wenan) }
                return (
                  <div key={ o._id } className='quan-container-content-card'>
                    <WenanCardItem { ...o } style={{ height: '100%' }}  />
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
    )
  }
}

export default Home
