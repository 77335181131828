import React from 'react'
import { browserHistory } from 'react-router' 
import { setStore, send } from 'mulan-lib'
import { Radio, Button, Tag, Message, Input } from 'antd'
import { processRequest, setTitle } from 'lib'
import GAds from 'lib/component/gads'
import MyIcon from 'component/my-icon'
import { ossLink } from 'config'
import './index.less'

const { Search } = Input;

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      from: 0,
      max: 6,
      sortBy: 'hot',
      loading: true,
      isEnd: false,
    }
    setTitle('MEMES|迷因|梗图 制作')
  }
  handleToNew(item) {
    window.open(`/memes/new/${ item._id }`)
  }
  handleToFree() {
    browserHistory.push(`/memes/free`)
  }
  componentDidMount() {
    const { kw } = this.props.params
    if (!!kw) {
      this.handleSearch(kw)
    } else {
      this.handleGetList()
    }
  }
  handleGetList() {
    const { from, max, sortBy, kw = '' } = this.state

    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/list',
        data: {
          from: from,
          max: max,
          sortBy,
          kw: [kw],
        }
      }),
      data => {
        const { list } = this.state
        if (data.length == 0) {
          this.setState({
            loading: false,
            isEnd: true,
            from: from - max
          }, () => {
            Message.warn('已经到底啦')
          })
        } else {
          setTimeout(() => {
            this.setState({
              loading: false,
              list: data,
              isEnd: false,
            })
          }, 1000)
          
        }
        
      }
    )
  }
  handleSortByChange(e) {
    this.setState({
      from: 0,
      sortBy: e.target.value,
      loading: true,
    }, this.handleGetList.bind(this))
  }
  handleGetPrev() {
    const { from } = this.state
    if (from > 0) {
      this.setState({
        from: from - this.state.max,
        loading: true,
      }, this.handleGetList.bind(this))
    }
  }
  handleGetMore() {
    this.setState({
      from: this.state.from + this.state.max,
      loading: true,
    }, this.handleGetList.bind(this))
  }
  handleSearch(value) {
    this.setState({
      kw: value,
      from: 0,
      loading: true,
    }, this.handleGetList.bind(this))
  }
  handleTagClick(tag) {
    this.handleSearch(tag)
  }
  handleKWChange(e) {
    this.setState({
      kw: e.target.value,
    })
  }
  render() {
    const { loading = false, list, sortBy, from, isEnd = false, kw = '' } = this.state
    return (
      <div className='meme'>
        <div className='meme-search'>
          <Search disabled={ loading } value={ kw } allowClear placeholder="请输入关键字，如：四格图" onChange={ this.handleKWChange.bind(this) } onSearch={ this.handleSearch.bind(this) }/>
        </div>
        <div className='meme-tools'>
          <div>
            <Radio.Group  disabled={ loading } defaultValue="hot" value={ sortBy } buttonStyle="solid" onChange={ this.handleSortByChange.bind(this) }>
              <Radio.Button value="hot">热门</Radio.Button>
              <Radio.Button value="new">最新</Radio.Button>
            </Radio.Group>
            <Button className='meme-tools-free' type='ghost' icon='smile' onClick={ this.handleToFree.bind(this) }>自定义</Button>
          </div>
          <Search disabled={ loading } value={ kw } allowClear style={{ width: '300px' }} placeholder="请输入关键字，如：四格图" onChange={ this.handleKWChange.bind(this) } onSearch={ this.handleSearch.bind(this) }/>
        </div>
        <div className='meme-list'>
          {
            !!loading && (
              <div className='meme-list-loading'>加载中...</div>
            )
          }
          
          {
            list.map((item, index) => {
              const { pic, _id, name, tags, count } = item
              return (
                <div key={_id} className='meme-list-item'>
                  <div className='meme-list-item-img'>
                    <img src={ `${ ossLink }${ pic }?x-oss-process=image/resize,w_150,limit_0` } alt='' />
                  </div>
                  <div className='meme-list-item-info'>
                    <div className='meme-list-item-info-name'>{ name }</div>
                    <div className='meme-list-item-info-tags'>
                      { 
                        tags.split(',').map((tag, index) => {
                          return <Tag key={index} onClick={ () => this.handleTagClick(tag) }>{ tag }</Tag>
                        })
                      }
                    </div>
                    <div className='meme-list-item-info-action'>
                      <div className='meme-list-item-info-action-hot' >
                        <MyIcon type='icon-fire' /><span>{ count }</span>
                      </div>
                      <Button size='small' icon='thunderbolt' type='ghost' onClick={() => this.handleToNew(item)}>去制作</Button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {
          list.length > 0 && (
            <div className='meme-action'>
              <Button disabled={ from === 0 || loading } type='ghost' onClick={this.handleGetPrev.bind(this)}>上一页</Button>
              <Button disabled={ isEnd || loading } type='ghost' onClick={this.handleGetMore.bind(this)}>下一页</Button>
            </div>
          )
        }
        <GAds slot='1110868974' />
      </div>
    )
  }
}
export default Home
