import React from 'react'
import { message, Icon, Popover } from 'antd'
import { moment } from 'mulan-lib'
import Clipboard from 'lib/component/copyToClipbord'
import { baseApiUrl } from 'config'
import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  state = {
    isMatchKeyword: false
  }
  componentWillMount() {
    const { keywords = [] } = this.props
    if (keywords.length > 0) {
      this.setState({
        isMatchKeyword: true
      })
    }
  }
  handleMatchKeywords(words) {
    // console.log(words)
    const { keywords } = this.props

    let matched = words.match(new RegExp(keywords.join("|"), "g"))
    matched = [...new Set(matched)]
    // console.log(matched)
    matched.map(o => {
      const rep = `<span class='article-list-item-title-light'>${ o }</span>`
      words = words.replace(new RegExp(o, "g"), rep)
    })

    return words
  }
  handleGetImg(url, href, height) {
    let styles = 'width: 100%'
     var randomId = 'img' + url
     window[`img${url}`] = `<div style="cursor: pointer; display: flex; justify-content: center;align-items: center;height: 100%; background-image: url(${ url });background-position: 50%;background-size: cover;"><a target="_blank" href="${ href }" style="display: block; position: absolute; width: 100%; height: 100%;top:0;left: 0 "></a></div><style>body{margin:0} html{ font-size: ${ window.document.documentElement.style.fontSize } }</style><script>window.onload = function() { var el = parent.document.getElementById(\'' + randomId + '\'); el.height = 100+\'%\'; } <' + '/script>`
     var iframeStr = `<iframe id=${randomId} src="javascript:parent['img${url}']" frameBorder="0" scrolling="no" width="100%" height="100%" ></iframe>`
     return iframeStr
  }
  render() {
    const { item, mode = 'normal', imageHeight = '1.5rem', style = {} } = this.props
    const { isMatchKeyword } = this.state
    const clickUrl = `//www.wenanmen.com/pages/s/${ item._id }.html`
    return (
      <div className={ `article-list-item ${ mode }` } style={style}>
        <div className='article-list-item-pic'
             dangerouslySetInnerHTML={{
               __html: this.handleGetImg(item.thumb_url, clickUrl, imageHeight)
             }} />
        <a href={clickUrl} target='_blank' style={{ color: 'initial' }}>
          <div className='article-list-item-content'>
              {
                isMatchKeyword ? (
                  <div className='article-list-item-content-title' dangerouslySetInnerHTML={{ __html: this.handleMatchKeywords(item.title) }}></div>
                ) : (
                  <div className='article-list-item-content-title'>
                  { item.title }
                  </div>
                )
              }
              <div className='article-list-item-content-sub-title'>
                { item.digest && item.digest.length > 32 ? `${item.digest.substr(0, 32)}...` : item.digest }
              </div>
            <div className='article-list-item-content-create-time'>- { moment('MM/DD')(item.createdTime) } -</div>
          </div>
        </a>
      </div>
    )
  }
}
