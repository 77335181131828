import React from 'react'

import './index.less'

export default class Index extends React.Component {
  static options = {
    addGlobalClass: true
  }
  render() {
    const { style = {}, title, showBar = true, extra, extraIconStyle = {},
            extraStyle = {}, align = 'center', showArrow = true, onClick,
            separator = false, noPadding = false, normal = false, larger = false,
            secondary = false, extraIcon, arrowType = 'arrow', arrowStyle = {}
          } = this.props
    const arrowSrc = {
      'arrow': require('img/common/ic_arrow_right.png'),
      'refreash': require('img/common/ic_refreash.png')
    }[arrowType]
    return (
      <div className={ `block-title ${ separator ? 'separator' : '' } ${ noPadding ? 'no-padding' : '' } ${ normal ? 'normal' : '' } ${ larger ? 'larger' : '' } ${ secondary ? 'secondary' : '' } flex flex-justify-between flex-align-${ align }` }>
        <div className='title flex flex-justify-start flex-align-center' style={ style }>
          {
            showBar && <div className='block-title-bar' />
          }
          { title }
        </div>
        {
          extra && (
            <div onClick={ onClick } className='extra flex flex-align-center flex-justify-end'>
              <span style={extraStyle}>{ extra }</span>
              {
                showArrow && (
                  <img
                    src={
                      arrowSrc
                    }
                    className='arrow'
                    mode='widthFix'
                    style={ arrowStyle }
                  />
                )
              }
            </div>
          )
        }

      </div>
    )
  }
}
